import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ToggleService {

  private toggleSubject = new Subject<boolean>();
  toggle$ = this.toggleSubject.asObservable();

  toggle(){
    this.toggleSubject.next(true);
  }

  toggleOn(){
    this.toggleSubject.next(true);
  }
  
  toggleOff(){
    this.toggleSubject.next(false);
  }

  constructor() { }
}
