import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, of } from 'rxjs';
import { environment } from '../../../environments/environment'; 
import { AppraisalZoneEdw } from '../../models/appraisals/appraisal-zone-edw';
import { getList, getListHeaders } from '../pagination/listHelper';
import { AppraisalZone } from '../../models/appraisals/appraisal-zone';

@Injectable({
  providedIn: 'root'
})

export class AppraisalZonesService {
  baseUrl = environment.apiUrl;
  // appraisalZonesEdwCache = new Map();
  // private appraisalZonesEdwSource = new BehaviorSubject<AppraisalZoneEdw[] | null>(null);
  // appraisalZonesEdw$ = this.appraisalZonesEdwSource.asObservable();

  appraisalZonesCache = new Map();
  private appraisalZonesSource = new BehaviorSubject<AppraisalZone[] | null>(null);
  appraisalZones$ = this.appraisalZonesSource.asObservable();

  constructor(private http: HttpClient) { 
    //this.getAppraisalZonesEdw();
    this.getAppraisalZones();
  }

  // setAppraisalZonesEdw(appraisalZones: AppraisalZoneEdw[]) {
  //   this.appraisalZonesEdwCache.set('appraisalZonesEdw', appraisalZones);
  //   this.appraisalZonesEdwSource.next(appraisalZones);
  // }

  setAppraisalZones(appraisalZones: AppraisalZone[]) {
    this.appraisalZonesCache.set('appraisalZones', appraisalZones);
    this.appraisalZonesSource.next(appraisalZones);
  }


  // private getAppraisalZonesEdw() {
  //   const response = this.appraisalZonesEdwCache.get('appraisalZonesEdw');
  //   console.log(response);
  //   if (response) return of(response);

  //   let params = getListHeaders();
  //   console.log("sending appraisal zones api call");
  //   return getList<AppraisalZoneEdw[]>(this.baseUrl + 'appraisalzones/edw', params, this.http).subscribe({
  //     next:(response => {
  //       console.log("received api response");
  //       console.log(response);
  //       this.setAppraisalZonesEdw(response as AppraisalZoneEdw[]);
  //       return response;
  //     })
  //   })
  // }

  private getAppraisalZones() {
    const response = this.appraisalZonesCache.get('appraisalZones');
    if (response) return of(response);

    let params = getListHeaders();
    console.log("sending appraisal zones api call");
    return getList<AppraisalZone[]>(this.baseUrl + 'appraisalzones', params, this.http).subscribe({
      next:(response => {
        console.log("received api response");
        console.log(response);
        this.setAppraisalZones(response as AppraisalZone[]);
        return response;
      })
    })
  }


  
}