import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, of } from 'rxjs';
import { environment } from '../../../environments/environment'; 
import { TreeProduct } from '../../models/forest-service/tree-product';
import { TreeProductParams } from '../../models/params/treeProductParams'
import { getList, getListHeaders } from '../pagination/listHelper';

@Injectable({
  providedIn: 'root'
})

export class TreeProductsService {
    baseUrl = environment.apiUrl;
    treeProducts: TreeProduct[] = [];
    treeProductParams: TreeProductParams | undefined;

  constructor(private http: HttpClient) { 
    this.treeProductParams = new TreeProductParams();
  }

  getTreeProductParams() {
    return this.treeProductParams;
  }

  setTreeProductParams(treeProductParams: TreeProductParams) {
    this.treeProductParams = treeProductParams;
  }

  resetTreeProductParams() {
    if (this.treeProductParams) {
      this.treeProductParams = new TreeProductParams();
    }
  }  

  setTreeProducts(treeProducts: TreeProduct[]) {
    localStorage.setItem('treeProducts', JSON.stringify(treeProducts));
  }

  getTreeProducts() {
    const treeProductsString = localStorage.getItem('treeProducts');
    let treeProducts: TreeProduct[] = treeProductsString != null ? JSON.parse(treeProductsString) : null;
    
    if(treeProducts != null && treeProducts.length == 0) return of(treeProducts);

    let params = getListHeaders();

    return getList<TreeProduct[]>(this.baseUrl + 'tree-products', params, this.http).pipe(
      map(response => {
        this.setTreeProducts(response as TreeProduct[]);
        return response;
      })
    )
  }

  getTreeProductsByAppraisalZone(appraisalZoneId: number) {
    let params = getListHeaders();

    return getList<TreeProduct[]>(this.baseUrl + 'tree-products/get-by-appraisal-zone/' + appraisalZoneId, params, this.http).pipe(
      map(response => {
        this.setTreeProducts(response as TreeProduct[]);
        return response;
      })
    )
  }

  getTreeProductById(treeProductId: number) {    
    return this.http.get<TreeProduct>(this.baseUrl + 'tree-products/' + treeProductId);
  }

  
}