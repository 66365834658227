<div class="grid-container accordion-grid-container" style="margin-bottom:50px;">
    <div class="grid-row">
        <div class="tablet:grid-col inputs">
            <div class="usa-form-group">
                <label class="usa-label" for="appraiser-name">Appraiser Name</label>
                <app-text-input [formControl]="$any(form.controls['appraiserName'])" [label]="'Appraiser Name'"></app-text-input>
            </div>

            <div class="usa-form-group">
                <label class="usa-label" id="appraisal-date-label" for="appraisal-date">Appraisal Date </label>
                <div class="usa-hint" id="appraisal-date-hint"></div>
              
              <div class="usa-date-picker">
                <app-date-picker [formControl]="$any(form.controls['appraisalDate'])" [label]="'Appraisal Date'"></app-date-picker>
              </div>
            </div>
        </div>
    </div>
    <div class="grid-row">
        <div class="tablet:grid-col inputs">
            <div class="usa-form-group">
                <label class="usa-label" for="regionCode">Region<sup>*</sup></label>
                <ng-select formControlName="regionCode" name="regionCode" id="regionCode" class="usa-rfd" (change)="setForests()"  placeholder="Select a Region" [loading]="loadingHandler.isLoading" aria-label="Region Code" [inputAttrs]="{'aria-label': 'Select a Region'}">
                    <ng-option *ngFor="let region of regions" [value]="region.regionCode">{{region.regionCode}} - {{region.regionName}}</ng-option>
                </ng-select>
               
            </div>

            <div class="usa-form-group">
                <label class="usa-label" for="forestCode">Forest <sup>*</sup></label>
                <ng-select formControlName="forestCode" name="forestCode" id="forestCode" class="usa-rfd" (change)="setDistricts()"  placeholder="Select a Forest" aria-label="Select a Forest" [inputAttrs]="{'aria-label': 'Select a Forest'}">
                    <ng-option *ngFor="let forest of forests" [value]="forest.forestCode">{{forest.forestCode}} - {{forest.forestName}}</ng-option>
                </ng-select>
               
            </div>

        </div>
    </div>
    <div class="grid-row">
        <div class="tablet:grid-col inputs">
            <div class="usa-form-group">
                <label class="usa-label" for="districtCode">District<sup>*</sup></label>
                <ng-select formControlName="districtCode" name="districtCode" id="districtCode" class="usa-rfd" (change)="setAppraisalZone()" placeholder="Select a District" aria-label="Select a District" [inputAttrs]="{'aria-label': 'Select a District'}">
                   <ng-option *ngFor="let district of districts" [value]="district.districtCode">{{district.districtCode}} - {{district.districtName}}</ng-option>
                </ng-select>
               
            </div>

            <div class="usa-form-group">
                <label class="usa-label" for="appraisalZoneCode">Appraisal Zone</label>
                <input formControlName="appraisalZoneCode" class="usa-input" id="appraisalZoneCode" name="appraisalZoneCode" placeholder="Zone number"/>
            </div>
        </div>
    </div>
</div>