import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { WwpaIndicesService } from '../../../../services/wwpa-indices/wwpa-indices.service';
import { User } from '../../../../models/user/user';
import { ModalService } from '../../../../services/modal.service';
import { WwpaIndexDto } from '../../../../models/wwpa/wwpa-index-dto';
import { FocusService } from '../../../../services/focus.service';
import { WwpaIndex } from '../../../../models/wwpa/wwpa-index';
import { WwpaIndexView } from '../../../../models/wwpa/wwpa-index-view';
import { WwpaIndexValue } from '../../../../models/wwpa/wwpa-index-value';
import { WwpaCalculationsService } from '../../../../services/calculations/wwpa-calculations.service';
import { DecimalPipe } from '@angular/common';


@Component({
    selector: 'app-wwpa-indices',
    templateUrl: './wwpa-indices.component.html',
    styleUrls: ['./wwpa-indices.component.scss'],
    providers: [DecimalPipe],
    standalone: false
})

export class WwpaIndicesComponent implements OnInit {
    showForm:boolean = true;
    currentUrl: any ='';
  
    @ViewChild('skip')  skip!:ElementRef;
    @ViewChild('toggleBtn') toggleBtn!:ElementRef;
    @ViewChild('statusBtns') statusBtns!:ElementRef;
    @ViewChild('statusForm') statusForm!:ElementRef;
    @ViewChild('statusTitle') statusTitle!:ElementRef;
    @ViewChild('saveStatus') saveStatus!:ElementRef;
    @ViewChild('main') main!:ElementRef;
    @ViewChild('modal') modal!:ElementRef;
    @ViewChild('isActive') isActive!:ElementRef;
    @ViewChild('inActive') inActive!:ElementRef;
    
    @Input() labelPosition!: 'before' | 'after'; 
 
    wwpaIndices: WwpaIndex[] = [];
    wwpaIndicesView: WwpaIndexView[] = [];
    filteredWwpaIndicesView: WwpaIndexView[] = [];
    
    form!: FormGroup;
    rowsForm!: FormArray;
    newIndexYYYYMM!: string;
    newMonthYear!: string;
    currentActiveMonth!: string;
    statuses: string[] = ['Active', 'Inactive'];
    selectedStatuses: string[] = [];
    currentQuarter: number = 0;
    currentQuarterStart: boolean = false;
    ulHeader!: string;
    ulItems: string[] = [];
    recommendActive: boolean = false;
    ulActiveItems: string[] = [];
    ulInactiveItems: string[] = [];
    newIndices: WwpaIndexValue[] = [];

    constructor(private fb:FormBuilder,
        private wwpaIndicesService: WwpaIndicesService, 
        private wwpaCalculationsService: WwpaCalculationsService,
        private decimalPipe: DecimalPipe,
        public modalService:ModalService, 
        public focusService:FocusService) {
        this.modalService.onSaveSubmit.subscribe(()=>{
          this.onSave();
        })
        this.focusService.onFocus.subscribe(()=>{
          this.showFocus();
        });
    
        this.modalService.onFoucsModal.subscribe(()=>{
          this.focusModal();
        })
    }

    ngOnInit(){
        this.initForm();
        this.getWwpaIndices();
        this.getWwpaIndicesView();
        this.setCurrentActiveMonth();
    }

    initForm(){
      this.form = this.fb.group({
        status: new FormControl(1, Validators.required),
        rows: this.fb.array(this.wwpaIndices.map(val => this.fb.group({
            parent_Id: new FormControl(),
            wwpaIndexName: new FormControl(),
            wwpaIndexCode: new FormControl(),
            tsaSpeciesCode: new FormControl(),
            child_Id: new FormControl(),
            wwpaIndexId: new FormControl(),
            indexYYYYMM: new FormControl(),
            indexValue: new FormControl(),
            unitOfMeasureId: new FormControl(),
            uomShortName: new FormControl(),
            child_InactivatedWhenUtc: new FormControl(),
            child_IsActiveFlag: new FormControl(),
            child_InsertedById: new FormControl(),
            child_InsertedByName: new FormControl(),
            child_InsertedWhenUtc: new FormControl(),
            child_UpdatedById: new FormControl(),
            child_UpdatedByName: new FormControl(),
            child_UpdatedWhenUtc: new FormControl(),
            
            action: new FormControl('existingRecord'),
            isEditable: new FormControl(false),
            isNewRow: new FormControl(false),
            error: new FormControl(""),
            hasError: new FormControl(false),
        })
      ))
    }); 
    }

    getWwpaIndices(){
        this.wwpaIndicesService.wwpaIndices$.subscribe({
            next: response => {
            console.log(response);
              if(response != null){
                this.wwpaIndices = response;
              }  
            }
        })
    }

    getWwpaIndicesView(){
      this.wwpaIndicesService.wwpaIndicesView$.subscribe({
          next: response => {
          console.log(response);
            if(response != null){
              this.wwpaIndicesView = response;
              this.filteredWwpaIndicesView = this.wwpaIndicesView;
              this.setCurrentActiveMonth();
              this.setRows();
            }
              
          }
      })
    }

    setCurrentActiveMonth() {
       // Quarterly Indices Logic for:
          // 03 March           > Inserted in 04 April
          // 06 June               > Inserted in 07 July
          // 09 September   > Inserted in 10 October
          // 12 December    > Inserted in 01 January
          // Set As Active

          let today = new Date();
          let month: string;
          let year = today.getFullYear().toString();
          let indexMonth = today.getMonth();
          this.currentQuarter = indexMonth == 0 ? 4 : Math.floor(((indexMonth) / 12 ) * 4 ) + 1;
          if(this.currentQuarter == 0)
            this.currentQuarter = 1;
          if(indexMonth == 0){
            month = '12';
            year = (today.getFullYear() - 1).toString();
          } else {
            month = indexMonth.toString().length > 1 ? indexMonth.toString() : '0' + indexMonth.toString();
          }
          console.log(month);
          this.newMonthYear = month + '/' + year;
          this.newIndexYYYYMM = year + month;
          console.log(this.currentQuarter); 
          // if(indexMonth == 0 || indexMonth % 3 == 0)
          //   this.currentQuarterStart = true;

          if(this.wwpaIndicesView?.length > 0) {
            let activeIndexValues = this.wwpaIndicesView.filter(i => i.child_IsActiveFlag == 1);
            if(activeIndexValues && activeIndexValues.length > 0) {
              let activeIndexYYYYMM = activeIndexValues[0].indexYYYYMM;
              let activeMonth = activeIndexYYYYMM.toString().substring(4, 6);
              let activeYear = activeIndexYYYYMM.toString().substring(0, 4);
              this.currentActiveMonth = activeMonth + '/' + activeYear;
            }

          }

       
          
    }

    setRows(){
        if(this.filteredWwpaIndicesView != null){
            this.form = this.fb.group({
                status: new FormControl('active', Validators.required),
                rows: this.fb.array(this.filteredWwpaIndicesView.map(val => this.fb.group({
                    parent_Id: new FormControl(val.parent_Id),
                    wwpaIndexName: new FormControl(val.wwpaIndexName),
                    wwpaIndexCode: new FormControl(val.wwpaIndexCode),
                    tsaSpeciesCode: new FormControl(val.tsaSpeciesCode),
                    child_Id: new FormControl(val.child_Id),
                    wwpaIndexId: new FormControl(val.wwpaIndexId),
                    indexYYYYMM: new FormControl(val.indexYYYYMM),
                    indexValue: new FormControl(val.indexValue),
                    unitOfMeasureId: new FormControl(val.unitOfMeasureId),
                    uomShortName: new FormControl(val.uomShortName),
                    child_InactivatedWhenUtc: new FormControl(val.child_InactivatedWhenUtc ? new Date(val.child_InactivatedWhenUtc) : null),
                    child_IsActiveFlag: new FormControl(val.child_IsActiveFlag),
                    child_InsertedById: new FormControl(val.child_InsertedById),
                    child_InsertedByName: new FormControl(val.child_InsertedByName),
                    child_InsertedWhenUtc: new FormControl(val.child_InsertedWhenUtc ? new Date(val.child_InsertedWhenUtc) : null),
                    child_UpdatedById: new FormControl(val.child_UpdatedById),
                    child_UpdatedByName: new FormControl(val.child_UpdatedByName),
                    child_UpdatedWhenUtc: val.child_UpdatedWhenUtc ? new FormControl(new Date(val.child_UpdatedWhenUtc)) : null,
                    
                    action: new FormControl('existingRecord'),
                    isEditable: new FormControl(false),
                    isNewRow: new FormControl(false),
                    error: new FormControl(""),
                    hasError: new FormControl(false),
                })
              )),
              insertedRows: null
            }); 
      
            this.rowsForm = this.form.controls['rows'] as FormArray;
            
        }
    }

    addRows(){
      console.log(this.showForm)
      if(!this.showForm){
        for(let i = 0; i < this.wwpaIndices.length; i++){
          this.rowsForm.insert(i,
            this.fb.group({
              parent_Id: new FormControl(this.wwpaIndices[i].id),
              wwpaIndexName: new FormControl(this.wwpaIndices[i].wwpaIndexName),
              wwpaIndexCode: new FormControl(this.wwpaIndices[i].wwpaIndexCode),
              tsaSpeciesCode: new FormControl(this.wwpaIndices[i].tsaSpeciesCode),
              child_Id: new FormControl(),
              wwpaIndexId: new FormControl(),
              indexYYYYMM: new FormControl(this.newIndexYYYYMM),
              indexValue: new FormControl(0.00),
              unitOfMeasureId: new FormControl(2),
              uomShortName: new FormControl('MBF'),
              child_InactivatedWhenUtc: new FormControl(null),
              child_IsActiveFlag: new FormControl(null),
              child_InsertedById: new FormControl(null),
              child_InsertedByName: new FormControl(null),
              child_InsertedWhenUtc: new FormControl(null),
              child_UpdatedById: new FormControl(null),
              child_UpdatedByName: new FormControl(null),
              child_UpdatedWhenUtc: new FormControl(null),
              
              action: new FormControl('existingRecord'),
              isEditable: new FormControl(true),
              isNewRow: new FormControl(true),
              error: new FormControl(""),
              hasError: new FormControl(false),
          })
          )
        }
      }
     console.log(this.rowsForm)
    
    }

    removeRows(){
      if(this.showForm){
        for(let i = 0; i < this.wwpaIndices.length; i++) {
         this.rowsForm.removeAt(0);
        }
      }
     
    }

    toggleForm(){
        this.showForm = !this.showForm;
        this.toggleBtn.nativeElement.style.display= "none"; /*Green toggle btn*/
        this.saveStatus.nativeElement.style.display="none";
        this.statusTitle.nativeElement.style.display= "none";
        this.statusBtns.nativeElement.style.display= "none";
        this.statusForm.nativeElement.style.display= "flex";
        if(!this.showForm)
          this.addRows();
    }

    toggleStatus(statuses: string[]){
      let length = statuses.length;
      console.log(statuses, statuses.length);
      if(length == 2){
        this.filteredWwpaIndicesView = this.wwpaIndicesView;
      } else if(length == 1) {
        let status = statuses[0];
        if(status == "Active"){
          this.filteredWwpaIndicesView = this.wwpaIndicesView.filter(i => i.child_IsActiveFlag == 1)
        }else if(status == "Inactive"){
          this.filteredWwpaIndicesView = this.wwpaIndicesView.filter(i => i.child_IsActiveFlag == 0)
        }
      } else {
        this.filteredWwpaIndicesView = this.wwpaIndicesView.filter(i => i.child_IsActiveFlag != 0 && i.child_IsActiveFlag != 1)
      }
      this.setRows();
    }

    showPrevControls(){
        this.showForm = !this.showForm;
        this.toggleBtn.nativeElement.style.display= "block"; 
        this.statusBtns.nativeElement.style.display= "flex";
        this.statusTitle.nativeElement.style.display= "block";
        this.statusForm.nativeElement.style.display= "block";/*form*/
        this.saveStatus.nativeElement.style.display="none";
       
        if(this.showForm && this.rowsForm.length > this.wwpaIndices.length)
          this.removeRows();
    }

    checkSave(){
      this.ulHeader = '';
      this.ulItems = [];
      this.ulActiveItems = [];
      this.ulInactiveItems = [];
      let hasErrors = false;
      this.rowsForm.controls.forEach(obj => {
        let indexValue = obj.get('indexValue')?.value
        if(isNaN(indexValue) || indexValue === null){
          obj.get('hasError')?.patchValue(true);
          obj.get('error')?.patchValue('Field is required.');
          hasErrors = true;
        }else{
          obj.get('hasError')?.patchValue(false);
          obj.get('error')?.patchValue('');
        }
      });
      if(hasErrors)
        return;
      
      let status = this.form.controls['status'].value;
      console.log(status)
      this.newIndices = this.rowsForm?.controls
        .filter(r => r.get('child_Id')?.value === null)
        .map(i => ({ 
          wwpaIndexId: i.get('parent_Id')?.value,
          indexValue: i.get('indexValue')?.value, 
          indexYYYYMM: i.get('indexYYYYMM')?.value, 
          isActiveFlag: status === "active" ? 1 : 0,
          unitOfMeasureId: i.get("unitOfMeasureId")?.value 
        })) as WwpaIndexValue[];
        
      if(this.currentQuarterStart) {
        this.ulHeader = 'Beginning Q' + this.currentQuarter + ' on ' + this.newMonthYear;
        this.ulItems.push('Recommended to insert new indices as ACTIVE, to begin a new quarter.');
      } else {
        for(let indexValue of this.newIndices) {
          let newIndexValue = indexValue.indexValue;
          let activeIndex = this.wwpaIndicesView.filter(i => i.parent_Id == indexValue.wwpaIndexId)[0];
          let activeIndexValue = activeIndex.indexValue;
          //(activeIndexValue) + (activeIndexValue * x) = newIndexValue
          let deviation = activeIndexValue > 0 ? ((newIndexValue - activeIndexValue) / activeIndexValue) * 100 : 100;
          let displayDeviation = (deviation >= 1 ? deviation : deviation * -1).toString();
          if(deviation >= 10 || deviation <= -10) {
            this.recommendActive = true;
            let increase = deviation >= 1 ? 'increase' : 'decrease';
            this.ulActiveItems.push(activeIndex.wwpaIndexCode + ' - ' + this.decimalPipe.transform(displayDeviation, '1.2-2') + '% ' + increase + ' from Active'); 
            
          } else if(deviation >= 9 || deviation <= -9) {
            let increase = deviation >= 1 ? 'increase' : 'decrease';
            this.ulInactiveItems.push(activeIndex.wwpaIndexCode + ' - ' + this.decimalPipe.transform(displayDeviation, '1.2-2') + '% ' + increase + ' MoM'); 
          }
        }
    
        if(this.recommendActive && this.ulActiveItems.length > 0) {
          this.ulHeader = 'More than 1 Dimensional indices changed by 10% +-';
          this.ulActiveItems.push('Recommended to insert new indices as ACTIVE.');
          this.ulItems = this.ulActiveItems;
          this.statusControl.patchValue('active');
        }
        else if(this.ulInactiveItems.length > 0) {
          this.ulHeader ='No indices above 10% +- threshold. However, notable changes to consider:';
          this.ulInactiveItems.push('Recommended to insert new indices as INACTIVE.');
          this.ulItems = this.ulInactiveItems;
          this.statusControl.patchValue('inactive');
        } else {
          this.ulHeader ='No indices above above 10% +- threshold.';
          this.ulInactiveItems.push('Recommended to insert new indices as INACTIVE.');
          this.ulItems = this.ulInactiveItems;
          this.statusControl.patchValue('inactive');
        }

        console.log(this.newIndices);
      }

      this.modalService.open('modal-1');
    }

    onSave(){

      let hasErrors = false;
      let status = this.form.controls['status'].value;
      console.log(status);
      this.rowsForm.controls.forEach(obj => {
        let indexValue = obj.get('indexValue')?.value
        if(isNaN(indexValue) || indexValue === null){
          obj.get('hasError')?.patchValue(true);
          obj.get('error')?.patchValue('Field is required.');
          hasErrors = true;
        }else{
          obj.get('hasError')?.patchValue(false);
          obj.get('error')?.patchValue('');
        }
      });
      if(hasErrors)
        return;

      this.wwpaIndicesService.createWwpaIndices(this.newIndices).subscribe({
        next: _ => {
          this.showForm = !this.showForm;
          this.toggleBtn.nativeElement.style.display= "block"; 
          this.statusBtns.nativeElement.style.display= "flex";
          this.statusTitle.nativeElement.style.display= "block";
          this.saveStatus.nativeElement.style.display="none";  
          this.modalService.close('modal-1');
        }, error: err => {
          console.log(err);
          this.cancelSave()
        }
      });
      
    }

    cancelSave(){
      this.showForm = !this.showForm;
      this.toggleBtn.nativeElement.style.display= "block"; 
      this.statusBtns.nativeElement.style.display= "flex";
      this.statusTitle.nativeElement.style.display= "block";
      this.saveStatus.nativeElement.style.display="none";
      this.removeRows();
  }
    
    editWwpaIndex(id: number, i: number) { 
      let originalIndex!: WwpaIndex;
      let index!: FormGroup;
      let indexValue: number;
      //TODO: set all in edit mode other than this one back to original values
      
      if(id !== null){
        let index = this.rowsForm?.controls.filter(r => r.get('child_Id')?.value == id)[0] as FormGroup;
        index?.controls['isEditable'].patchValue(true);
      }else {
        let index = this.rowsForm.at(i) as FormGroup;
        index?.controls['isEditable'].patchValue(true);
      }       
    }
    
    getWwpaIndexControl(id: number, i: number) : FormControl{
      let index!: FormGroup;
      if(id !== null){
        index = this.rowsForm?.controls.filter(r => r.get('child_Id')?.value == id)[0] as FormGroup;
      }else {
        index = this.rowsForm.at(i) as FormGroup;
      } 
      return index.controls["indexValue"] as FormControl;
    }
    
    saveWwpaIndex(id: number, i: number) {
      let originalIndex!: WwpaIndexView;
      let index!: FormGroup;
      let indexValue: number;

      if(id !== null){
        originalIndex = this.wwpaIndicesView?.filter(r => r.child_Id == id)[0];
        index = this.rowsForm?.controls.filter(r => r.get('child_Id')?.value == id)[0] as FormGroup;
        indexValue = index?.controls['indexValue'].value;
        if(isNaN(indexValue) || indexValue === null){
          index.controls['hasError'].patchValue(true);
          index.controls['error'].patchValue('Field is required.');
          return;
        }else{
          index.controls['hasError'].patchValue(false);
          index.controls['error'].patchValue('');
        }
        originalIndex.indexValue = indexValue;
        this.wwpaIndicesService.updateWwpaIndex(originalIndex).subscribe({
          next: _ => {
            console.log("updating single index", originalIndex);
            index?.controls['isEditable'].patchValue(false);
          }
        });
      } 
           
         
    }
    
    cancelSaveWwpaIndex(id: number, i: number) {
      let originalIndex!: WwpaIndexView;
      let index!: FormGroup;
      let indexValue: number;
      
      if(id !== null){
        originalIndex = this.wwpaIndicesView?.filter(r => r.child_Id == id)[0];
        indexValue = originalIndex.indexValue;
        index = this.rowsForm?.controls.filter(r => r.get('child_Id')?.value == id)[0] as FormGroup;
        index?.controls['indexValue'].patchValue(indexValue);
      } else {
        indexValue = 0.00;
        index = this.rowsForm.at(i) as FormGroup;
        index?.controls['indexValue'].patchValue(indexValue);
      } 
           
      index?.controls['isEditable'].patchValue(false);
    }
    
    showFocus(){
      this.main.nativeElement.focus();
    }
  
    focusModal(){
      this.modal.nativeElement.focus()
    }

    get statusControl(): FormControl{
      return this.form.controls['status'] as FormControl;
    }

    setActive(){
      //console.log(this.statusControl.value);
      let active = this.statusControl.value;
      if(active === "active"){
        this.form.controls['status'].patchValue(1);
        this.isActive.nativeElement.checked=true;
        this.inActive.nativeElement.checked=false;
        console.log("active",this.statusControl.value);
      } else if(active === "inactive"){
        this.form.controls['status'].patchValue(0);
        console.log("IN ACTIVE",this.statusControl.value);
        this.isActive.nativeElement.checked=false;
        this.inActive.nativeElement.checked=true;
      }
      if(this.newIndices.length > 0) {
        this.newIndices.forEach(i => { i.isActiveFlag = active === "active" ? 1 : 0 });
      }
    }

  
    
}


