import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, of } from 'rxjs';
import { environment } from '../../../environments/environment'; 
import { getList, getListHeaders } from '../pagination/listHelper';
import { HarvestPrescription } from '../../models/forest-service/harvest-prescription';

@Injectable({
  providedIn: 'root'
})

export class HarvestPrescriptionsService {
  baseUrl = environment.apiUrl;

  harvestPrescriptionsCache = new Map();
  private harvestPrescriptionsSource = new BehaviorSubject<HarvestPrescription[] | null>(null);
  harvestPrescriptions$ = this.harvestPrescriptionsSource.asObservable();

  constructor(private http: HttpClient) { 
    this.getHarvestPrescriptions();
  }

  setHarvestPrescriptions(harvestPrescriptions: HarvestPrescription[]) {
    this.harvestPrescriptionsCache.set('harvestPrescriptions', harvestPrescriptions);
    this.harvestPrescriptionsSource.next(harvestPrescriptions);
  }

  private getHarvestPrescriptions() {
    const response = this.harvestPrescriptionsCache.get('harvestPrescriptions');
    if (response) return of(response);

    let params = getListHeaders();
    console.log("sending harvest-prescriptions api call");
    return getList<HarvestPrescription[]>(this.baseUrl + 'harvest-prescriptions', params, this.http).subscribe({
      next:(response => {
        console.log("received api response");
        console.log(response);
        this.setHarvestPrescriptions(response as HarvestPrescription[]);
        return response;
      })
    })
  }


  
}