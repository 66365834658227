
<div class="market-value-accordion-panel">
    <h4 class="usa-accordion__heading market-value-accordion-heading">
        <button
    type="button"
    id="panel-3-button"
    class="usa-accordion__button"
    aria-expanded="false"
    aria-controls="b-a3"
    >
        <svg class="usa-icon usa-icon--size-4 accordion-icon" aria-hidden="true" focusable="false" role="img" width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M6.48124 0.456055C4.45286 0.569849 3.17236 0.893074 2.25437 1.81107C0.837494 3.22794 0.837494 5.50836 0.837494 10.0692V19.7443C0.837494 24.3051 0.837494 26.5855 2.25437 28.0024C3.67124 29.4193 5.95165 29.4193 10.5125 29.4193H20.1875C24.7483 29.4193 27.0287 29.4193 28.4456 28.0024C29.8625 26.5855 29.8625 24.3051 29.8625 19.7443V10.0692C29.8625 5.50836 29.8625 3.22794 28.4456 1.81107C27.5276 0.893074 26.2471 0.569849 24.2187 0.456055V1.20325C24.2187 3.87493 22.053 6.04075 19.3812 6.04075H11.3187C8.64707 6.04075 6.48124 3.87493 6.48124 1.20325V0.456055ZM7.28749 9.66888C6.61958 9.66888 6.07812 10.2104 6.07812 10.8783C6.07812 11.5462 6.61958 12.0876 7.28749 12.0876H8.09374C8.76166 12.0876 9.30312 11.5462 9.30312 10.8783C9.30312 10.2104 8.76166 9.66888 8.09374 9.66888H7.28749ZM12.9312 9.66888C12.2633 9.66888 11.7219 10.2104 11.7219 10.8783C11.7219 11.5462 12.2633 12.0876 12.9312 12.0876H23.4125C24.0804 12.0876 24.6219 11.5462 24.6219 10.8783C24.6219 10.2104 24.0804 9.66888 23.4125 9.66888H12.9312ZM7.28749 15.3126C6.61958 15.3126 6.07812 15.8541 6.07812 16.522C6.07812 17.1899 6.61958 17.7314 7.28749 17.7314H8.09374C8.76166 17.7314 9.30312 17.1899 9.30312 16.522C9.30312 15.8541 8.76166 15.3126 8.09374 15.3126H7.28749ZM12.9312 15.3126C12.2633 15.3126 11.7219 15.8541 11.7219 16.522C11.7219 17.1899 12.2633 17.7314 12.9312 17.7314H23.4125C24.0804 17.7314 24.6219 17.1899 24.6219 16.522C24.6219 15.8541 24.0804 15.3126 23.4125 15.3126H12.9312ZM7.28749 20.9564C6.61958 20.9564 6.07812 21.4979 6.07812 22.1658C6.07812 22.8337 6.61958 23.3751 7.28749 23.3751H8.09374C8.76166 23.3751 9.30312 22.8337 9.30312 22.1658C9.30312 21.4979 8.76166 20.9564 8.09374 20.9564H7.28749ZM12.9312 20.9564C12.2633 20.9564 11.7219 21.4979 11.7219 22.1658C11.7219 22.8337 12.2633 23.3751 12.9312 23.3751H23.4125C24.0804 23.3751 24.6219 22.8337 24.6219 22.1658C24.6219 21.4979 24.0804 20.9564 23.4125 20.9564H12.9312Z" fill="#538200"/>
            </svg>
            
        <span>Appraisal Method</span>
        <div class="accordion-button-details" *ngIf="appraiserRoleId != user_Roles.Guest">
            Appraisal Selection: {{ appraisalSelection }}
        </div>
    </button>
</h4>
<div id="b-a3" class="usa-accordion__content usa-prose market-value-accordion-content" hidden>
    <div class="grid-container accordion-grid-container" style="padding-right:0 !important;">
        <!--start form-->
        <form [formGroup]="form" id="formGroup" cdkScrollable>
        <div class="grid-row"> <!---->
            <mat-button-toggle-group name="appraisals" [formControl]="appraisals" aria-label="appraisals">
                <div class="grid-row" style="width:100%;">
                    <div class="tablet:grid-col cols">
                        <mat-button-toggle tabindex="" value="Standard Rate Appraisal" [disabled]="isDisabled" (keyup)="setAppraisalType(appraisalTypes.StandardRate)" (click)="setAppraisalType(appraisalTypes.StandardRate)">
                            <div class="containerbtn" style="display:flex;justify-content: space-between;align-items:center;" #standardRatetooltip>
                                <div class="title">Standard Rate</div>
                                <mat-icon  tabindex="0" matTooltipPosition="above" matTooltipClass="below" matTooltip="A simple estimate of fair market value. Most applicable in low complexity projects." [matTooltipPosition]="'above'" [matTooltipDisabled]="isDisabled">
                                    <span class="material-icons">help</span>
                                </mat-icon>
                            </div>
                        </mat-button-toggle>

                        <mat-button-toggle [disabled]="appraisalGroupForms.controls.length > 1"  tabindex="" value="Streamlined TE" (keyup)="setAppraisalType(appraisalTypes.StreamlinedTe)" (click)="setAppraisalType(appraisalTypes.StreamlinedTe)">
                            <div class="containerbtn" style="display:flex;justify-content: space-between;align-items:center;">
                                <div class="title">Streamlined TE</div>
                                <mat-icon  tabindex="0" matTooltipPosition="above" matTooltipClass="below" matTooltip="A weighted average TE appraisal with streamlined cost estimation tools." [matTooltipPosition]="'above'" [matTooltipDisabled]="appraisalGroupForms.controls.length > 1" >
                                <span class="material-icons">help</span>
                                </mat-icon>
                            </div>
                        </mat-button-toggle>
                    </div>
                    
                </div>
                <div class="grid-row"> 
                    <div class="tablet:grid-col cols">

                        <mat-button-toggle tabindex="-1" [disabled]="true" value="Weighted Average TE" (keyup)="setAppraisalType(appraisalTypes.WeightedAverageTe)" (click)="setAppraisalType(appraisalTypes.WeightedAverageTe)">
                            <div class="containerbtn" style="display:flex;justify-content: space-between;align-items:center;">
                                <div class="title">Weighted Average TE</div>
                                <mat-icon  tabindex="-1" matTooltipPosition="above" matTooltipClass="below" matTooltip="A simple estimate of fair market value. Most applicable in low complexity projects." [matTooltipPosition]="'above'" matTooltipDisabled>
                                    <span class="material-icons">help</span>
                                </mat-icon>
                            </div>
                        </mat-button-toggle>

                        <mat-button-toggle tabindex="-1" [disabled]="true" value="Residual Value Appraisal" (keyup)="setAppraisalType(appraisalTypes.ResidualValue)" (click)="setAppraisalType(appraisalTypes.ResidualValue)">
                            <div class="containerbtn" style="display:flex;justify-content: space-between;align-items:center;">
                            <div class="title">Residual Value </div>
                            <mat-icon  tabindex="-1" matTooltipPosition="above" matTooltipClass="below" matTooltip="A simple estimate of fair market value. Most applicable in low complexity projects." [matTooltipPosition]="'above'" matTooltipDisabled>
                                <span class="material-icons">help</span>
                            </mat-icon>
                            </div>
                        </mat-button-toggle>
                    </div>
                </div>

                <div class="grid-row"> 
                    <div class="tablet:grid-col cols">

                        <mat-button-toggle tabindex="-1" [disabled]="true" value="Multiple Regression TE" (keyup)="setAppraisalType(appraisalTypes.MultipleRegressionTe)" (click)="setAppraisalType(appraisalTypes.MultipleRegressionTe)">
                            <div class="containerbtn" style="display:flex;justify-content: space-between; align-items:center;">
                                <div class="title">Multiple Regression TE</div>
                                <mat-icon  tabindex="-1" matTooltipPosition="above" matTooltipClass="below" matTooltip="A simple estimate of fair market value. Most applicable in low complexity projects." [matTooltipPosition]="'above'" matTooltipDisabled>
                                    <span class="material-icons">help</span>
                                </mat-icon>
                            </div>
                        </mat-button-toggle>

                        <mat-button-toggle tabindex="-1" [disabled]="true" value="Rate Redetermination Appraisal" (keyup)="setAppraisalType(appraisalTypes.RateDetermination)" (click)="setAppraisalType(appraisalTypes.RateDetermination)">
                            <div class="containerbtn" style="display:flex;justify-content: space-between;align-items:center;">
                                <div class="title">Rate Redetermination </div>
                                <mat-icon  tabindex="-1" matTooltipPosition="above" matTooltipClass="below" matTooltip="A simple estimate of fair market value. Most applicable in low complexity projects." [matTooltipPosition]="'above'" matTooltipDisabled>
                                <span class="material-icons">help</span>
                                </mat-icon>
                            </div>
                        </mat-button-toggle>

                    </div>
                </div>
                    
            </mat-button-toggle-group>
            
                
            <!--EndSecondDiv-->
        </div><!---->
      </form>
      <!--end form-->
    </div>
    
   
    
    <div class="usa-form-group" id="continue-button">
        <button type="button" class="usa-button usa-button--outline continue-button" (click)="continue($event)">Continue</button>
    </div>
</div>