import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, map, of } from 'rxjs';
import { environment } from '../../../environments/environment'; 
import { getList, getListHeaders } from '../pagination/listHelper';
import { AppraisalSale } from '../../models/appraisals/appraisal-sale';
import { ApiResult } from '../../models/api/api-result';

@Injectable({
  providedIn: 'root'
})

export class AppraisalSalesService {
  baseUrl = environment.apiUrl;

  appraisalSalesCache = new Map();
  private appraisalSalesSource = new BehaviorSubject<AppraisalSale[] | null>(null);
  appraisalSales$ = this.appraisalSalesSource.asObservable();

  constructor(private http: HttpClient) { 
    this.getAppraisalSales();
  }

  setAppraisalSales(appraisalSales: AppraisalSale[]) {
    this.appraisalSalesCache.set('appraisalSales', appraisalSales);
    this.appraisalSalesSource.next(appraisalSales);
  }

  private getAppraisalSales() {
    const response = this.appraisalSalesCache.get('appraisalSales');
    if (response) return of(response);

    let params = getListHeaders();
    console.log("sending appraisal-sales api call");
    return getList<AppraisalSale[]>(this.baseUrl + 'appraisal-sales', params, this.http).subscribe({
      next:(response => {
        console.log("received api response");
        console.log(response);
        this.setAppraisalSales(response as AppraisalSale[]);
        return response;
      })
    })
  }

  createAppraisalSale(appraisalSale: AppraisalSale){
    console.log(appraisalSale)
    return this.http.post<ApiResult>(this.baseUrl + 'appraisal-sales', appraisalSale).pipe(
      map(response => {
        console.log(response);
          return response;
      })
    )
  }

  updateAppraisalSale(appraisalSale: AppraisalSale){
      return this.http.put<ApiResult>(this.baseUrl + 'appraisal-sales', appraisalSale).pipe(
        map(response => {
            return response;
        })
      )
  }


  
}