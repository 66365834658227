import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, map, of, throwError } from 'rxjs';
import { environment } from '../../../environments/environment'; 
import { getList, getListHeaders } from '../pagination/listHelper';
import { WwpaIndexView } from '../../models/wwpa/wwpa-index-view';
import { WwpaIndexDto } from '../../models/wwpa/wwpa-index-dto';
import { WwpaIndexValue } from '../../models/wwpa/wwpa-index-value';
import { UsersService } from '../users/users.service';
import { WwpaIndex } from '../../models/wwpa/wwpa-index';

@Injectable({
  providedIn: 'root'
})

export class WwpaIndicesService {
    baseUrl = environment.apiUrl;

    wwpaIndicesCache = new Map();
    private readonly wwpaIndicesSource = new BehaviorSubject<WwpaIndex[] | null>(null);
    wwpaIndices$ = this.wwpaIndicesSource.asObservable();

    wwpaIndicesViewCache = new Map();
    private readonly wwpaIndicesViewSource = new BehaviorSubject<WwpaIndexView[] | null>(null);
    wwpaIndicesView$ = this.wwpaIndicesViewSource.asObservable();

  constructor(private readonly usersService: UsersService, private http: HttpClient) { 
    console.log("constructor");
    this.getCurrentUserRole();
  }

  setWwpaIndices(wwpaIndices: WwpaIndex[]) {
    this.wwpaIndicesSource.next(wwpaIndices);
  }

  setWwpaIndicesView(wwpaIndicesView: WwpaIndexView[]) {
    this.wwpaIndicesViewSource.next(wwpaIndicesView);
  }

  private getCurrentUserRole(){
    this.usersService.currentUserRole$.subscribe({
        next: _ => {
          console.log("User Role Updated.");
          this.getWwpaIndices();   
          this.getWwpaIndicesView();   
        }
    })
}

private getWwpaIndices() {
  let params = getListHeaders();
    getList<WwpaIndex[]>(this.baseUrl + 'wwpaindices', params, this.http).subscribe({
      next:(response => {
        this.setWwpaIndices(response as WwpaIndex[]);
      }),
      error: err => {
        console.log(err);
      }
    })
}


  private getWwpaIndicesView() {
    let params = getListHeaders();
      getList<WwpaIndexView[]>(this.baseUrl + 'wwpaindices/indices-view', params, this.http).subscribe({
        next:(response => {
          this.setWwpaIndicesView(response as WwpaIndexView[]);
        }),
        error: err => {
          console.log(err);
        }
      })
  }

  getWwpaIndexById(wwpaIndexId: number) {  
    return this.http.get<WwpaIndex>(this.baseUrl + 'wwpaindices/' + wwpaIndexId);
  }

  updateWwpaIndex(index: WwpaIndexView){
    let wwpaIndices = this.wwpaIndicesViewSource.value as WwpaIndexView[];
    let indexToUpdate = wwpaIndices.findIndex((wwpaIndex: WwpaIndexView) => wwpaIndex.child_Id === index.child_Id);
    let wwpaIndex = wwpaIndices[indexToUpdate];
    wwpaIndex.indexValue = index.indexValue;

    return this.http.put<WwpaIndexValue>(this.baseUrl + 'wwpaindices', wwpaIndex).pipe(
      map(response => {
        wwpaIndex.child_UpdatedById = response.updatedById;
        wwpaIndex.child_UpdatedWhenUtc = response.updatedWhen_UTC;
        wwpaIndex.child_UpdatedByName = response.updatedBy!.username;
        wwpaIndices[indexToUpdate] = wwpaIndex;
        this.setWwpaIndicesView(wwpaIndices);
      })
    )
  }

  createWwpaIndices(wwpaIndices: WwpaIndexValue[]){
    return this.http.post<WwpaIndexValue[]>(this.baseUrl + 'wwpaindices', wwpaIndices).pipe(
      map(response => {
        this.getWwpaIndicesView();
        //this.setWwpaIndicesView(response);
        return response;
        })
      )
      .pipe(
        catchError(err => {
          return throwError(() => err);
        })
    )
  }
 
}