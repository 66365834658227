<div class="usa-width-one-whole nt-info-area">
    <div [formGroup]="form" class="grid-container" id="">
      <div [formGroup]="appraisalZoneForm">
        <div class="grid-row">
          <div class="tablet:grid-col">
                <div class="usa-form-group usa-form-group--enlarge">
                    <h2 class="usa-main-page-header">Standard Rate Maintenance Page</h2>
                    <h4 class="subtitle">Edit the Standard Rates for Appraisal Groups</h4>
                </div>
          </div>
      </div>
        <div class="grid-row">
          <div class="tablet:grid-col inputs usa-form">
              <div class="usa-form-group">
                  <label class="usa-label" for="regionCodes">Region<sup>*</sup></label>
                  
                  <ng-select formControlName="regionCode" [formControl]="$any(appraisalZoneForm.controls['regionCode'])" name="regionCode" id="regionCode" class="usa-rfd" (change)="setForests()"  placeholder="Select a Region" [loading]="loadingHandler.isLoading" aria-label="Select a Region" [inputAttrs]="{'aria-label': 'Select a Region'}" [ariaLabel]="'Select a Region'">
                    <ng-option *ngFor="let region of regions" [value]="region.regionCode">{{region.regionCode}} - {{region.regionName}}</ng-option>
                  </ng-select>
              </div>
          </div>
          <div class="tablet:grid-col inputs usa-form">
            <div class="usa-form-group">
              <label class="usa-label" for="forestCode">Forest <sup>*</sup></label>

              <ng-select formControlName="forestCode" [formControl]="$any(appraisalZoneForm.controls['forestCode'])" name="forestCode" id="forestCode" class="usa-rfd" (change)="setDistricts()"  placeholder="Select a Forest" [ariaLabel]="'Select a Forest'">
                <ng-option *ngFor="let forest of forests" [value]="forest.forestCode">{{forest.forestCode}} - {{forest.forestName}}</ng-option>
              </ng-select>
              
          </div>
          </div>
          <div class="tablet:grid-col inputs usa-form">
            <div class="usa-form-group">
              <label class="usa-label" for="districtCode">District<sup>*</sup></label>


           <ng-select formControlName="districtCode" [formControl]="$any(appraisalZoneForm.controls['districtCode'])" name="districtCode" id="districtCode" class="usa-rfd" (change)="setAppraisalZone()"  placeholder="Select a District" [ariaLabel]="'Select a District'">
                <ng-option *ngFor="let district of districts" [value]="district.districtCode">{{district.districtCode}} - {{district.districtName}}</ng-option>
            </ng-select>
              
          </div>
        </div>
        </div>
        <div class="grid-row">
          <div class="tablet:grid-col-3 inputs">
            <div class="usa-form-group">
                <label class="usa-label" for="appraisalZoneCode">Appraisal Zone</label>
                <input [formControl]="$any(appraisalZoneForm.controls['appraisalZoneCode'])" class="usa-input" id="appraisalZoneCode" name="appraisalZoneCode" placeholder="Zone number"/>
            </div>
          </div>
        </div>
        <div class="grid-row">
          <div class="tablet:grid-col-4 inputs">
              <div class="usa-form-group usa-form-group--modifier totalvolume">
                <label class="usa-label" for="unitOfMeasure">Unit of Measure</label>
                  <mat-button-toggle-group name="unitOfMeasure" [formControl]="$any(appraisalZoneForm.controls['uomId'])" (change)="applyFilter()" aria-label="Unit of Measure" class="usa-button-toggle">
                    <mat-button-toggle *ngFor="let u of uom" [value]="u.id">{{u.uomShortName}}</mat-button-toggle>
                  </mat-button-toggle-group>
              </div>
          </div>
          <div class="tablet:grid-col-3 inputs">
            <div class="usa-form-group usa-form-group--modifier totalvolume">
              <label class="usa-label" for="treeCondition">Condition</label>
                <mat-button-toggle-group name="treeCondition" [formControl]="$any(appraisalZoneForm.controls['treeCondition'])" (change)="applyFilter()" aria-label="Tree Condition" class="usa-button-toggle">
                    <mat-button-toggle value="Live">Live</mat-button-toggle>
                    <mat-button-toggle value="Dead">Dead</mat-button-toggle>
                </mat-button-toggle-group>
            </div>
        </div>
      </div>
     
      </div>
     

     <div *ngIf="appraisalZoneCode != null" class="grid-row">
        <div class="tablet:grid-col">
          <div class="usa-table-container--scrollable">
            <table class="usa-table">
              <caption>
                Standard Rates
              </caption>
              <thead>
                <tr>
                  <th scope="col">Product</th>
                  <th scope="col">Species</th>
                  <th scope="col">Condition</th>
                  <th scope="col">Appraisal Group</th>
                  <th scope="col" style="min-width: 200px;">Standard Rate</th>
                  <th scope="col">Unit of Measure</th>
                  <th scope="col">Updated Date</th>
                  <th scope="col">Updated By</th>
                  <th scope="col">Inserted Date</th>
                  <th scope="col">Inserted By</th>
                </tr>
              </thead>
              <tbody>
                <ng-container formArray="rows" *ngFor="let rate of rowsForm?.controls as FormArray">
                  <tr [ngClass]="rate.get('isEditable')?.value === true ? 'edit-rate-row': ''" >
                    <th scope="row">{{rate.get('product')?.value}}</th>
                    <td>
                      {{rate.get('species')?.value}}
                    </td>
                    <td>
                      {{rate.get('condition')?.value}}
                    </td>
                    <td>
                      {{rate.get('appraisalGroupName')?.value}}
                    </td>
                    <td>
                      <div *ngIf="!rate.get('isEditable')?.value">
                        <div class="grid-row" style="text-align:right;">
                          <div class="tablet:grid-col" style="padding-right:20px;">
                            {{rate.get('rate')?.value}}
                          </div>
                          <div  class="tablet:grid-col-2" style="margin-left:5px">
                            <button type="buttom" (click)="editRate(rate.get('id')?.value)" aria-label="Edit">
                              <svg class="edit-rate-btn"  width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M3 17.25V21H6.75L17.81 9.94L14.06 6.19L3 17.25ZM20.71 7.04C21.1 6.65 21.1 6.02 20.71 5.63L18.37 3.29C17.98 2.9 17.35 2.9 16.96 3.29L15.13 5.12L18.88 8.87L20.71 7.04Z" fill="black"/>
                                </svg> 
                            </button> 
                          </div>
                          
                        </div>
                                             
                      </div>
                      <div *ngIf="rate.get('isEditable')?.value">
                        
                        <div class="grid-row">
                          <div class="tablet:grid-col">
                            
                            <input style="text-align:right;" type="number" class="form-control usa-input input-number unit-measure" [formControl]="getRateControl(rate.get('id')?.value)" [step]="0.01" [min]="0.00" max="maxValue" aria-label="Standard Rate"/> 
                            <div class="error">
                              {{error}}
                            </div>
                          </div>
                          <div class="tablet:grid-col-2" style="display:flex;gap:10px;align-items: end;justify-content:center;">
                            <button type="button" (click)="saveRate(rate.get('id')?.value)" aria-label="Save">
                              <svg class="edit-rate-btn" width="24" height="24" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M5.99997 11.17L1.82997 7L0.409973 8.41L5.99997 14L18 2L16.59 0.589996L5.99997 11.17Z" fill="black"/>
                              </svg>
                            </button>
                            <button type="button" (click)="cancelSaveRate(rate.get('id')?.value)" aria-label="Cancel">
                              <svg class="edit-rate-btn"  width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z" fill="black"/>
                              </svg>
                            </button>
                                
                          </div>
                        </div>
                       
                       
                      </div>
                      
                     
                        
                    </td>
                    
                    <td>
                      {{rate.get('uomShortName')?.value}}
                    </td>
                    <td>
                      {{ rate.get('updatedDate')?.value ? rate.get('updatedDate')?.value.toLocaleDateString("en-US") : ''}}
                    </td>
                    <td>
                      {{rate.get('updatedBy')?.value}}
                    </td>
                    <td>
                      {{rate.get('insertedDate')?.value.toLocaleDateString("en-US")}}
                    </td>
                    <td>
                      {{rate.get('insertedBy')?.value}}
                    </td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
  
          </div>

        </div>
      </div>
  </div>
</div>



       
    

