<div class="usa-width-one-whole nt-info-area">
    <div class="grid-container">
      <div>
        <div class="grid-row">
          <div class="tablet:grid-col">
                <div class="usa-form-group">
                    <h2>User Management</h2>
                    

                    <div class="usa-table-container--scrollable">
                      <table class="usa-table" style="width:100% !important">
                        <caption>
                          Pending Requests: {{ pendingRequests.length }}
                        </caption>
                        <thead>
                          <tr>
                            <th scope="col">First Name</th>
                            <th scope="col">Last Name</th>
                            <th scope="col" style="min-width: 200px;">Email</th>
                            <th scope="col" style="min-width: 200px;">Role</th>
                            <th scope="col" style="min-width: 200px;">Region</th>
                            <th scope="col">Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          <ng-container *ngIf="pendingRequests.length > 0">
                            <ng-container formArray="rows" *ngFor="let request of rowsForm?.controls as FormArray">
                              <tr [ngClass]="request.get('isEditable')?.value === true ? 'edit-rate-row': ''" >
                                <th scope="row">{{request.get('firstName')?.value}}</th>
                                <td>
                                  {{request.get('lastName')?.value}}
                                </td>
                                <td>
                                  {{request.get('email')?.value}}
                                </td>
                                <td>
                                  {{request.get('roleName')?.value}}
                                </td>
                                <td>
                                  {{request.get('regionName')?.value}}
                                </td>
                               
                                <td>
                                  <button type="button" (click)="viewStatus(request.get('id')?.value)" class="openLink" aria-label="View Status for Pending Request" tabindex="0">View Status</button>
                                </td>
                              </tr>
                            </ng-container>
                          </ng-container>
                          
                        </tbody>
                      </table>
                    </div>
                </div>
          </div>
        </div>
    </div>
</div>


<app-modal id="modal-3" aria-modal="true" aria-labelledby="User Role Management" >
  <div class="content-container" tabindex="-1" #modal Focustrap>
    <button type="button"  class="closeBtn" (click)="closeModal('modal-3')"  aria-label="Close Modal" tabindex="0" cdkFocusRegionStart>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path d="M20.7456 3.33001C20.3551 2.93948 19.7219 2.93948 19.3314 3.33001L12.037 10.6244L4.74263 3.33001C4.35211 2.93948 3.71894 2.93948 3.32842 3.33001C2.93789 3.72053 2.93789 4.3537 3.32842 4.74422L10.6228 12.0386L3.32844 19.3329C2.93791 19.7235 2.93791 20.3566 3.32844 20.7472C3.71896 21.1377 4.35213 21.1377 4.74265 20.7472L12.037 13.4528L19.3314 20.7472C19.7219 21.1377 20.3551 21.1377 20.7456 20.7472C21.1361 20.3566 21.1361 19.7235 20.7456 19.333L13.4512 12.0386L20.7456 4.74422C21.1361 4.3537 21.1361 3.72053 20.7456 3.33001Z" fill="#757575"/>
      </svg>
    </button>
    
  
    <div class="modalForm">
      <div class="labelWrapper" >
        <div>
          <h3>User Role</h3>
          <ng-container >
             <ul class="roleList">
                <li>{{userRoleRequest?.role?.name}}</li>
             </ul>
          </ng-container>
            
        </div>
        <div>
            <h3>Selected Region</h3>
            <ng-container >
              <ul class="roleList">
                 <li class="roleListRight">{{userRoleRequest?.region?.regionCode}} - {{userRoleRequest?.region?.regionName}}</li>
              </ul>
           </ng-container>


        </div>

      </div>
      <div class="list-grid-wrapper" #forests_districts *ngIf="showDiv">
        <div class="listbox_">
            <div class="btnselectioncontainer">
                <button mat-button tabindex="0" class="btnSelection"(click)="accordion().openAll();selectAllCheckboxes(true);" aria-label="Select All">Select All</button>
                <button mat-button tabindex="0" class="btnSelection" (click)="accordion().closeAll();selectAllCheckboxes(false);" aria-label="Deselect All">Deselect All</button> 
                <button mat-button tabindex="0" class="btnSelection" (click)="accordion().openAll();" aria-label="Expand All">Expand All</button> 
            </div>
            <mat-accordion multi>
                <mat-expansion-panel *ngFor="let forest of requestedRegion?.forests; let i = index" [expanded]="expanded(i)" (opened)="opened(i, forest.id)"  (closed)="closed(i, forest.id)">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <mat-checkbox 
                                tabindex="0"  
                                class="mat-checkbox" 
                                #forestCheckbox 
                                #checkbox="matCheckbox" 
                                [forestCheckboxValue]="checkbox" 
                                forestId="{{forest.id}}" 
                                aria-label="Select a Forest"
                                (change)="onForestChekboxChange($event, forest, i);"  
                                (keyup)="onForestCheckboxKeyUp($event, forest, i)"
                                (click)="stopPropagation($event, forest.id)">
                                {{forest.forestCode}}-{{forest.forestName}}  <strong class=""> {{ currentForestStatus(forest?.id)  }}</strong>
                            </mat-checkbox>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <ul>
                        <li *ngFor="let district of forest.districts">
                            <mat-checkbox  
                                [checked]="false" 
                                class="mat-checkbox" 
                                tabindex="0" 
                                #districtCheckbox 
                                #checkbox="matCheckbox" 
                                [districtCheckboxValue]="checkbox"
                                districtId="{{district.id}}" 
                                [class]="'districtCheckbox-' + forest.forestCode" 
                                aria-label="Select a district {{forest.districts}}"
                                (change)="onDistrictCheckboxChange($event, district)"
                                (keyup)="onDistrictCheckboxKeyUp($event, district)">
                                {{district.districtCode}}-{{district.districtName}} <strong  class=""> {{ currentDistrictStatus(district?.id) }}</strong>
                            </mat-checkbox>
                        </li>
                    </ul>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
      </div>
      <div class="notecontainerWrapper">
          <!--<label for="notes">Add Note (options)</label>
          <textarea id="notes" (blur)="onNotesChange($event)" (keyup)="onNotesChange($event)" rows="2" cols="50" placeholder="Enter text here"></textarea>-->

          <div class="buttonWrapper">
            <button class="btn btn-sm btn-success" (click)="submitRequest(true)" tabindex="0">Approve</button>
            <button class="btn btn-sm btn-danger" (click)="submitRequest(false)" tabindex="0">Reject</button>
            <button type="button" class="btn btn-sm btn-outline-success" (click)="submitRequest(null)" tabindex="0">Save as Pending</button>
          </div>
      </div>
    </div>
  </div>

</app-modal>