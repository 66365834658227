import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, of } from 'rxjs';
import { environment } from '../../../environments/environment'; 
import { TreeSpecies } from '../../models/forest-service/tree-species';
import { TreeSpeciesParams } from '../../models/params/treeSpeciesParams'
import { getList, getListHeaders } from '../pagination/listHelper';

@Injectable({
  providedIn: 'root'
})

export class TreeSpeciesService {
    baseUrl = environment.apiUrl;
    treeSpeciess: TreeSpecies[] = [];
    treeSpeciesParams: TreeSpeciesParams | undefined;

  constructor(private http: HttpClient) { 
    this.treeSpeciesParams = new TreeSpeciesParams();
  }
  
  getTreeSpecies() {

    let params = getListHeaders();

    return getList<TreeSpecies[]>(this.baseUrl + 'tree-species', params, this.http).pipe(
      map(response => {
        return response;
      })
    )
  }

  getTreeSpeciesByAppraisalZone(appraisalZoneId: number) {

    let params = getListHeaders();

    return getList<TreeSpecies[]>(this.baseUrl + 'tree-species/get-by-appraisal-zone/' + appraisalZoneId, params, this.http).pipe(
      map(response => {
        return response;
      })
    )
  }

  getTreeSpeciesById(treeSpeciesId: number) {    
    return this.http.get<TreeSpecies>(this.baseUrl + 'treespecies/' + treeSpeciesId);
  }

  
}