import { Component, EventEmitter, Host, Input, OnInit, Output } from '@angular/core';
import { Region } from '../../../../models/forest-service/region';
import { FormArray, FormBuilder, FormControl, FormGroup, FormGroupDirective } from '@angular/forms';
import { Forest } from '../../../../models/forest-service/forest';
import { environment } from '../../../../../environments/environment';
import { District } from '../../../../models/forest-service/district';
import { AppraisalGroup } from '../../../../models/appraisals/appraisal-group';
import { TooltipPosition } from '@angular/material/tooltip';
import { LoadingHandler } from '../../../../services/loading-handler';
import { AppraisalZone } from '../../../../models/appraisals/appraisal-zone';
import { EstimateMarketValueComponent } from '../../estimate-market-value.component';
import { UserRoles } from '../../../../models/enums/user-roles';
import { AppraisalZonesService } from '../../../../services/forest-service/appraisal-zones.service';
import { RegionsService } from '../../../../services/forest-service/regions.service';
import { HarvestPrescriptionsService } from '../../../../services/forest-service/harvest-prescriptions.service';
import { AppraisalSalesService } from '../../../../services/appraisals/appraisal-sales.service';
import { LoggingMethodsService } from '../../../../services/forest-service/logging-methods.service';
import { AppraisalSale } from '../../../../models/appraisals/appraisal-sale';
import { HarvestPrescription } from '../../../../models/forest-service/harvest-prescription';
import { LoggingMethod } from '../../../../models/forest-service/logging-method';

@Component({
    selector: 'app-general-information',
    templateUrl: './general-information.component.html',
    styleUrls: ['./general-information.component.scss'],
    standalone: false
})

export class GeneralInformationComponent implements OnInit {
    @Input() formGroupName!: string
    @Output() childEvent = new EventEmitter();
    values:any;
    baseUrl = environment.apiUrl;
    form!: FormGroup;
    volumeInfoForm!: FormGroup;
    isLoading = false;
    
    regions: Region[] = [];
    forests!: Forest[] | null;
    districts!: District[] | null;
    appraisalZones: AppraisalZone[] = [];
    harvestPrescriptions: HarvestPrescription[] = [];
    loggingMethods: LoggingMethod[] = [];
    
    appraisalGroup!: AppraisalGroup;
    

    positionOptions: TooltipPosition[] = ['below', 'above', 'left', 'right'];
    position = this.positionOptions[0];
    regionIdControl!: FormControl<number | null>;
    forestIdControl!: FormControl<number | null>;
    districtIdControl!: FormControl<number | null>;
    appraisalZoneIdControl!: FormControl<number | null>;
    appraisalZoneCodeControl!: FormControl<string | null>;
    regionCodeControl!: FormControl<string | null>;
    forestCodeControl!: FormControl<string | null>;
    districtCodeControl!: FormControl<string | null>;
    regionNameControl!: FormControl<string | null>;
    forestNameControl!: FormControl<string | null>;
    districtNameControl!: FormControl<string | null>;
    appraisalGroupIdControl!: FormControl<number | null>;
    appraisalGroupNameControl!: FormControl<string | null>;
    treeProductIdControl!: FormControl<number | null>;
    treeSpeciesIdControl!: FormControl<number | null>;
    treeConditionControl!: FormControl<string | null>;
    saleTypes!: FormArray<FormControl>;
    
    loadingHandler = new LoadingHandler()
    appraisalGroupForms!: FormArray<FormGroup>;
    initialAppraisalGroupForm!: FormGroup;
    appraiserRoleId!: number;
    userPortfolioForm!: FormGroup;
    appraisalSaleControl!: FormControl<AppraisalSale>;

    user_Roles = UserRoles;
    appraiser_RoleId!: number;

    constructor(@Host() parent: EstimateMarketValueComponent,
        private fb: FormBuilder, 
        private rootFormGroup: FormGroupDirective,
        private appraisalSalesService: AppraisalSalesService,
        private appraisalZonesService: AppraisalZonesService,
        private harvestPrescriptionsService: HarvestPrescriptionsService,
        private loggingMethodsService: LoggingMethodsService,
        private regionsService: RegionsService) {
            this.appraiserRoleId = parent.appraiserRoleId;
            
    }
  

    ngOnInit() {
        this.form = this.rootFormGroup.control.get(this.formGroupName) as FormGroup;
        this.userPortfolioForm = this.rootFormGroup.control.get("userPortfolio") as FormGroup;
        this.volumeInfoForm = this.rootFormGroup.control.get("volumeInformation") as FormGroup;
        this.appraisalGroupForms = this.volumeInfoForm.controls["appraisalGroups"] as FormArray;
        this.initialAppraisalGroupForm = this.appraisalGroupForms.controls[0];

        this.regionIdControl = this.form.controls['regionId'] as FormControl;
        this.regionCodeControl = this.form.controls['regionCode'] as FormControl;
        this.forestIdControl = this.form.controls['forestId'] as FormControl;
        this.forestCodeControl = this.form.controls['forestCode'] as FormControl;
        this.districtIdControl = this.form.controls['districtId'] as FormControl;
        this.districtCodeControl = this.form.controls['districtCode'] as FormControl;
        this.appraisalZoneIdControl = this.form.controls['appraisalZoneId'] as FormControl;
        this.appraisalZoneCodeControl = this.form.controls['appraisalZoneCode'] as FormControl;
        this.regionNameControl = this.form.controls['regionName'] as FormControl;
        this.forestNameControl = this.form.controls['forestName'] as FormControl;
        this.districtNameControl = this.form.controls['districtName'] as FormControl;
        this.appraisalGroupIdControl = this.initialAppraisalGroupForm.controls['appraisalGroupId'] as FormControl;
        this.appraisalGroupNameControl = this.initialAppraisalGroupForm.controls['appraisalGroupName'] as FormControl;
        this.treeProductIdControl = this.initialAppraisalGroupForm.controls['treeProductId'] as FormControl;
        this.treeSpeciesIdControl = this.initialAppraisalGroupForm.controls['treeSpeciesId'] as FormControl;
        this.treeConditionControl = this.initialAppraisalGroupForm.controls['treeCondition'] as FormControl;
        this.saleTypes = this.form.controls['saleTypes'] as FormArray;

        this.appraisalSaleControl = this.userPortfolioForm.get('appraisalSale') as FormControl;
        console.log(this.form);
        this.loadRegions();
        this.loadAppraisalZones();
        this.loadHarvestPrescriptions();
        this.loadLoggingMethods();
       
    }

    setForests() {
        this.forestIdControl.patchValue(null);
        this.forestCodeControl.patchValue(null);
        this.districtCodeControl.patchValue(null);
        this.appraisalZoneIdControl.patchValue(null);
        this.appraisalZoneCodeControl.patchValue(null);
        this.appraisalGroupIdControl.patchValue(null);
        this.appraisalGroupNameControl.patchValue(null);

        let r = this.regions?.filter(r => r.regionCode == this.regionCodeControl.value)[0];
        if(r != null){
            this.regionNameControl.patchValue(r.regionName);
            this.forests = r.forests.sort(
                (a, b) => a.forestCode > b.forestCode ? 1 : -1);
            this.districts = null;
        }

          // RESEST ENTIRE FORM
        //this.setAppraisalGroup(this.treeProductIdControl.value, this.treeSpeciesIdControl.value, this.treeConditionControl.value);
    }

    setDistricts() {
        this.districtCodeControl.patchValue(null);
        this.appraisalZoneCodeControl.patchValue(null); 

        let f = this.forests?.filter(f => f.forestCode == this.form.controls['forestCode'].value)[0];
        if(f != null){
            this.forestNameControl.patchValue(f.forestName);
            console.log(f);
    
            this.districts = f.districts.sort(
                (a, b) => a.districtCode > b.districtCode ? 1 : -1);
        }  
        
          // RESEST ENTIRE FORM
        //this.setAppraisalGroup(this.treeProductIdControl.value, this.treeSpeciesIdControl.value, this.treeConditionControl.value);  
    }

    
    checkAppraisalGroup() {
        throw new Error('Method not implemented.');
    }

    setAppraisalZone() {
        console.log(this.appraisalZones);
        let district = this.districts?.filter(d => d.districtCode == this.districtCodeControl.value)[0];
        console.log(district);
        this.districtNameControl.patchValue(district!.districtName);  
        let appraisalZone = this.appraisalZones?.filter(a => a.districts.some(d => d.id == district?.id))[0];
        console.log(appraisalZone);
        if(appraisalZone) {
            this.appraisalZoneIdControl.patchValue(appraisalZone.id);
            this.appraisalZoneCodeControl.patchValue(appraisalZone.appraisalZoneCode);
        }
        
        // RESEST ENTIRE FORM
        // this.setAppraisalGroup(this.treeProductIdControl.value,
        //     this.treeSpeciesIdControl.value, 
        //     this.treeConditionControl.value,
        //     null, null); 

    }

    // setAppraisalGroup(treeProductId: number | null, treeSpeciesId: number | null, treeCondition: string | null){
    //     if(treeProductId && treeSpeciesId && treeCondition){
    //         console.log('get ag test');
    //         this.appraisalGroupsService.getAppraisalGroupByZone(treeProductId, treeSpeciesId, treeCondition).subscribe({
    //             next: response => {
    //               if(response != null){
    //                 console.log(response);
    //                 this.appraisalGroup = response;
    //                 this.appraisalGroupIdControl.patchValue(this.appraisalGroup.id);
    //                 this.appraisalGroupNameControl.patchValue(this.appraisalGroup.appraisalGroupName);
    //               }
                    
    //             }
    //         })
    //     }        
    // }


    loadAppraisalZones() {
        this.appraisalZonesService.appraisalZones$.subscribe({
            next: response => {
                if (response) {
                    this.appraisalZones = response;
                    console.log(this.appraisalZones);
                }
            }
        })
    }

    loadHarvestPrescriptions() {
        this.harvestPrescriptionsService.harvestPrescriptions$.subscribe({
            next: response => {
                if (response) {
                    this.harvestPrescriptions = response;
                    console.log(this.harvestPrescriptions);
                }
            }
        })
    }

    loadLoggingMethods() {
        this.loggingMethodsService.loggingMethods$.subscribe({
            next: response => {
                if (response) {
                    this.loggingMethods = response;
                    console.log(this.loggingMethods);
                }
            }
        })
    }
    
    loadRegions() {
        if(this.appraiserRoleId == UserRoles.Guest) {
            this.regionsService.allRegions$.subscribe({
                next: response => {
                    if (response) {
                        this.regions = response;
                    }
                }
            })
        } else {
            this.regionsService.regions$.subscribe({
                next: response => {
                    if (response) {
                        this.regions = response;
                    }
                }
            })
        }       
    }

    get appraisalSale() : AppraisalSale {
        return this.appraisalSaleControl.value;
    }

    get cuttingUnitsCount() : number {
        return this.appraisalSaleControl.value?.cuttingUnits ? this.appraisalSale?.cuttingUnits.length : 0;
    }

    get totalAcreage() : number {
       return 0;
    }



    continue(event: any){
      
        event.preventDefault();
        let element: HTMLElement = document.getElementById("panel-2-button") as HTMLElement;
        element.click();
        element.scrollIntoView({behavior:'smooth',block:'start'});
    }

    setSalesTypes($event: any, index: number){
        let control = this.saleTypes.controls[index];
        if(!control.touched){
            control.patchValue(true);
            control.markAsTouched();
            console.log(control);
        }else{
            control.patchValue(!control.value);
        }
        console.log(this.saleTypes);

    }

    

}

