import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { FormArray, FormControl, FormGroup, FormGroupDirective } from '@angular/forms';
import { AppraisalTypes } from '../../../../models/enums/appraisal-types';
import { CalculationsService } from '../../../../services/calculations/calculations.service';
import { IVisited } from '../../../../models/visitors/visited';

@Component({
    selector: 'app-contract-calculations',
    templateUrl: './contract-calculations.component.html',
    styleUrls: ['./contract-calculations.component.scss'],
    standalone: false
})

export class ContractCalculationsComponent implements OnInit, IVisited {

    @Input() formGroupName!: string
    @Output() childEvent = new EventEmitter();
    @Input() arialabel = "Test"
    
    range = new FormControl(0);
    form!: FormGroup;
    generalInfoForm!: FormGroup;
    volumeInfoForm!: FormGroup;
    saleTypes!: FormArray;
    totalVolumeControl!: FormControl;
    minimumRateControl!: FormControl;
    standardRateControl!: FormControl;
    timberPropertyValueControl!: FormControl;
    regenerationCostControl!: FormControl;
    requiredCollectionsControl!: FormControl;

    appraisalTypes = AppraisalTypes;
    appraisalGroupForms!: FormArray<FormGroup>;
    initialAppraisalGroupForm!: FormGroup;
    estimatorForm!: FormGroup;
    contractBaseValueControl!: FormControl;

    formatThumbLabel(value: number): string {
        return `${value}%`;
      }

      title:string = "Appraisal Groups"
    
    constructor(private rootFormGroup: FormGroupDirective,
        public calculationsService: CalculationsService) {
           
    }

    public readProperty<T>(prop: string):T {
        let property = this[prop as keyof ContractCalculationsComponent];
        if (property == null) {
           throw Error(`Property "${prop}" does not exists`);
        }
        return property as T;
     }   

 

    ngOnInit() {
        this.form = this.rootFormGroup.control.get(this.formGroupName) as FormGroup;
        this.generalInfoForm = this.rootFormGroup.control.get('generalInformation') as FormGroup;
        
        this.volumeInfoForm = this.rootFormGroup.control.get('volumeInformation') as FormGroup;
        this.appraisalGroupForms = this.volumeInfoForm.controls["appraisalGroups"] as FormArray;
        this.initialAppraisalGroupForm = this.appraisalGroupForms.controls[0];

        this.estimatorForm = this.rootFormGroup.control.get('estimator') as FormGroup;
        
        this.minimumRateControl = this.initialAppraisalGroupForm.controls['minimumRate'] as FormControl;
        this.standardRateControl = this.initialAppraisalGroupForm.controls['standardRate'] as FormControl;
        this.saleTypes = this.generalInfoForm.controls["saleTypes"] as FormArray;
        this.totalVolumeControl = this.volumeInfoForm.controls['totalVolume'] as FormControl;
        this.timberPropertyValueControl = this.form.controls["timberPropertyValue"] as FormControl;
        this.regenerationCostControl = this.form.controls["regenerationCost"] as FormControl;
        this.requiredCollectionsControl = this.form.controls["requiredCollections"] as FormControl;
        this.timberPropertyValueControl.disable();
        this.regenerationCostControl.disable();

        this.contractBaseValueControl = this.estimatorForm.controls['contractBaseValue'] as FormControl;
        this.calculationsService.visitContractCalculationsComponent(this);

        this.calculationsService.requiredCollections$.subscribe({
            next: value => {
                if(value != null){
                    this.requiredCollectionsControl.patchValue(value.toFixed(2));
                } 
            }
        });
    }



    get uomControl(): FormControl{
        return this.initialAppraisalGroupForm.get('uomShortName') as FormControl;
    }

    get timberPropertyValue(): FormControl{
        if(this.saleTypes.controls[5].value === true){ 
            this.timberPropertyValueControl.enable();
        }else{
            this.timberPropertyValueControl.disable();
        }
        return this.timberPropertyValueControl;
    }

    get regenerationCost(): FormControl{
        if(this.saleTypes.controls[6].value === true){ 
            this.regenerationCostControl.enable();
        }else{
            this.regenerationCostControl.disable();
        }
        return this.regenerationCostControl;
    }

    get requiredCollections(): FormControl{ 
        return this.requiredCollectionsControl;       
    }
    
    get minimumRate(): FormControl{
        return this.minimumRateControl;
    }

    get standardRate(): FormControl{
      return this.standardRateControl;
    }

    get contractBaseValue(): number{
        return this.contractBaseValueControl.value;
    }

    get totalVolume(): number{
        console.log(this.totalVolumeControl);
        return this.totalVolumeControl.value;
    }
   
    onVolumeProcessedChange(event: any) {
        this.form.controls['partiallyProcessed'].patchValue(event.value);
    }
    onReforestationChange(event: any) {
        this.form.controls['reforestationRequired'].patchValue(event.value);
    }

    
    
}
