import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, of } from 'rxjs';
import { environment } from '../../../environments/environment'; 
import { getList, getListHeaders } from '../pagination/listHelper';
import { LoggingMethod } from '../../models/forest-service/logging-method';

@Injectable({
  providedIn: 'root'
})

export class LoggingMethodsService {
  baseUrl = environment.apiUrl;

  loggingMethodsCache = new Map();
  private loggingMethodsSource = new BehaviorSubject<LoggingMethod[] | null>(null);
  loggingMethods$ = this.loggingMethodsSource.asObservable();

  constructor(private http: HttpClient) { 
    this.getLoggingMethods();
  }

  setLoggingMethods(loggingMethods: LoggingMethod[]) {
    this.loggingMethodsCache.set('loggingMethods', loggingMethods);
    this.loggingMethodsSource.next(loggingMethods);
  }

  private getLoggingMethods() {
    const response = this.loggingMethodsCache.get('loggingMethods');
    if (response) return of(response);

    let params = getListHeaders();
    console.log("sending logging-methods api call");
    return getList<LoggingMethod[]>(this.baseUrl + 'logging-methods', params, this.http).subscribe({
      next:(response => {
        console.log("received api response");
        console.log(response);
        this.setLoggingMethods(response as LoggingMethod[]);
        return response;
      })
    })
  }


  
}