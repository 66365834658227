import { Component, ElementRef, EventEmitter, Host, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { User } from '../../../models/user/user';
import { UserRole } from '../../../models/user/user-roles/user-role';
import { FormArray, FormBuilder, FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { UserRoles } from '../../../models/enums/user-roles';
import { UsersService } from '../../../services/users/users.service';
import { take } from 'rxjs';
import { ToggleService } from '../../../services/toggle.service';
import { ModalService } from '../../../services/modal.service';
import { FocusService } from '../../../services/focus.service';
import { EstimateMarketValueComponent } from '../estimate-market-value.component';
import { LoadingHandler } from '../../../services/loading-handler';
import { AppraisalSale } from '../../../models/appraisals/appraisal-sale';
import { AppraisalSalesService } from '../../../services/appraisals/appraisal-sales.service';
import { RegionsService } from '../../../services/forest-service/regions.service';
import { Region } from '../../../models/forest-service/region';
import { Forest } from '../../../models/forest-service/forest';
import { District } from '../../../models/forest-service/district';
import { AppraisalZonesService } from '../../../services/forest-service/appraisal-zones.service';
import { AppraisalZone } from '../../../models/appraisals/appraisal-zone';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-user-portfolio',
  templateUrl: './user-portfolio.component.html',
  styleUrl: './user-portfolio.component.scss',
  standalone:false
})
export class UserPortfolioComponent implements OnInit {
  loadingHandler = new LoadingHandler()
  @Input() formGroupName!: string;
  @Output() childEvent = new EventEmitter();
  @Input() name!: string;
  @Input() arialabel!: string;
  user!: User;
  userRoles: UserRole[] = [];
  activeUserRole!: UserRole;
  appraisalForm: FormGroup = new FormGroup({});
  validationErrors: string[] = [];
  date: string | number | Date = new Date().toLocaleDateString("en-US", { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' });
  user_Roles = UserRoles;
  appraiser_RoleId!: number;
  @ViewChild('main') main!:ElementRef;
  @ViewChild('modal') modal!:ElementRef;
  appraiserRoleIdControl!: FormControl<number>;
  form!: FormGroup;
  generalInformationForm!: FormGroup;
  volumeInformationForm!: FormGroup;
  appraisalSalesForm!: FormArray<FormGroup>;
  appraisalSaleControl!: FormControl<AppraisalSale | null>;
  saleNameControl!: FormControl<string | null>;
  appraisalZoneCodeControl!: FormControl<string | null>;
  appraisalSales: AppraisalSale[] = [];
  regions: Region[] = [];
  forests: Forest[] = [];
  districts: District[] = [];
  appraisalZones: AppraisalZone[] = [];

  ariaSale = 'Sale Number';
  ariaSaleName = 'Sale Name';

  dynamicLabel = 'Initial label';

  
  //temp
  uomShortNameControl!: FormControl<string | null>;

  constructor(@Host() parent: EstimateMarketValueComponent,
    private rootFormGroup: FormGroupDirective,
    private fb: FormBuilder,
    private usersService: UsersService, 
    private appraisalSalesService: AppraisalSalesService,
    private appraisalZonesService: AppraisalZonesService,
    private regionsService: RegionsService,
    public toggleservice: ToggleService, 
    public modalService:ModalService,
    public focusService:FocusService,
    private renderer: Renderer2,
    private toastr: ToastrService,){
      this.focusService.onFocus.subscribe(()=>{
        this.showFocus();
      });
  
      this.modalService.onFoucsModal.subscribe(()=>{
        this.focusModal();
      });
    }

   


  ngOnInit() {
    this.appraiser_RoleId = this.appraiserRoleId;
    this.usersService.currentUser$.pipe(take(1)).subscribe({
      next: user => {
        if (user){
          this.user = user;
          this.userRoles = this.user?.userRoles;
          this.activeUserRole = user.currentUserRole;
        }
      }

    });

    
    
    this.form = this.rootFormGroup.control.get(this.formGroupName) as FormGroup;
    this.appraisalSalesForm = this.form.controls['appraisalSales'] as FormArray<FormGroup>;
    this.appraisalSaleControl = this.form.controls['appraisalSale'] as FormControl;
    this.saleNameControl = this.form.controls['saleName'] as FormControl;
    this.appraisalZoneCodeControl = this.form.controls['appraisalZoneCode'] as FormControl;
    this.appraiserRoleIdControl = this.form.controls['appraiserRoleId'] as FormControl;
    this.generalInformationForm = this.rootFormGroup.control.get("generalInformation") as FormGroup;
    this.volumeInformationForm = this.rootFormGroup.control.get("volumeInformation") as FormGroup;
    
    this.loadAppraisalSales();
    this.loadAppraisalZones();
    this.loadRegions();
    console.log(this.form);
    
    

  }

  
  loadAppraisalSales() {
    this.appraisalSalesService.appraisalSales$.subscribe({
        next: response => {
            if (response) {
                this.appraisalSales = response;
                console.log(this.appraisalSales);
                this.initForm();
            }
        }
    })
  }

  initForm() {
    if(this.appraisalSales != null){
      this.appraisalSalesForm = this.fb.array(this.appraisalSales.map(val => 
        this.fb.group({
          id: new FormControl(val.id),
          updatedBy: new FormControl(val.updatedBy),
          insertedBy: new FormControl(val.insertedBy),
          modifiedByName: (val.updatedBy != null) 
            ? new FormControl(val.updatedBy?.lastName + ', ' + val.updatedBy.firstName) 
            : new FormControl(val.insertedBy?.lastName + ', ' + val.insertedBy?.firstName) ,
          modifiedWhen_UTC: (val.updatedBy && val.updatedWhen_UTC)
            ? new FormControl(new Date(val.updatedWhen_UTC))
            : new FormControl(val.insertedWhen_UTC ? new Date(val.insertedWhen_UTC) : null),
          saleName: new FormControl(val.saleName),
          saleNumber: new FormControl(val.saleNumber),
          saleDate: new FormControl(val.saleDate),
          appraisalSatatus: new FormControl(val.appraisalStatus),
          totalVolume: new FormControl("12000/CCF"),
          district: new FormControl(val.district),
          districtId: new FormControl(val.districtId),
          districtName: new FormControl(val.district?.districtCode + " - " + val.district?.districtName),
          forest: new FormControl(val.district?.forest),
          forestId: new FormControl(val.district?.forestId),
          forestName: new FormControl(val.district?.forest.forestCode + " - " + val.district?.forest.forestName),
          region: new FormControl(val.district?.forest.region),
          regionId: new FormControl(val.district?.forest.regionId),
          regionName: new FormControl(val.district?.forest.region?.regionCode + " - " + val.district?.forest.region?.regionName),
          action: new FormControl('existingRecord'),
          isEditable: new FormControl(false),
          isNewRow: new FormControl(false),
        })
      )); 

      console.log(this.appraisalSalesForm);
    }   
  }

  loadRegions() {
    this.regionsService.regions$.subscribe({
        next: response => {
            if (response) {
                this.regions = response;
            }
        }
    });    
  }

  loadAppraisalZones() {
    this.appraisalZonesService.appraisalZones$.subscribe({
        next: response => {
            if (response) {
                this.appraisalZones = response;
                console.log(this.appraisalZones);
            }
        }
    })
}

  addNewSale(){
    if(this.appraisalSalesForm.controls.length > 0 && !this.appraisalSalesForm.controls[0].value.isEditable) {
      const rows = document.querySelectorAll(".appraisal-sale-row");
      rows.forEach(row => {
        if(row.classList.contains('select-appraisal-sale-row'))
          row.classList.remove('select-appraisal-sale-row')
      });
      let newAppraisalSale: AppraisalSale = {
        id: null,
        saleNumber: null,
        saleName: null,
        saleDate: new Date,
        appraisalStatus: 'Saved/Temp',
        districtId: null,
        district: null,
        cuttingUnits: [],
        inactivatedWhen_UTC: null,
        isActiveFlag: 1,
        insertedById: null,
        insertedBy: null,
        insertedWhen_UTC: null,
        updatedById: null,
        updatedBy: null,
        updatedWhen_UTC: null
      }
      this.appraisalSaleControl.patchValue(newAppraisalSale);
      this.saleNameControl.patchValue(null);
      this.appraisalZoneCodeControl.patchValue(null);
      this.appraisalSalesForm.insert(0,
        this.fb.group({
          id: new FormControl(null),
          updatedBy: new FormControl(null),
          insertedBy: new FormControl(null),
          modifiedByName: new FormControl(this.user.lastName + ', ' + this.user.firstName),
          modifiedWhen_UTC: new FormControl(new Date),
          saleName: new FormControl(null),
          saleNumber: new FormControl(null),
          saleDate: new FormControl(new Date),
          appraisalStatus: new FormControl(null),
          totalVolume: new FormControl(0.00),
          district: new FormControl(null),
          districtId: new FormControl(null),
          districtName: new FormControl(null),
          forest: new FormControl(null),
          forestId: new FormControl(null),
          forestName: new FormControl(null),
          region: new FormControl(null),
          regionId: new FormControl(null),
          regionName: new FormControl(null),
          action: new FormControl('existingRecord'),
          isEditable: new FormControl(true),
          isNewRow: new FormControl(true),
          error: new FormControl(""),
          hasError: new FormControl(false),
      })
      );
    }
  }

  removeRows(){
    this.appraisalSalesForm.removeAt(0);
  }

  setForests() {
    this.appraisalSalesForm.controls[0].get('forestId')?.patchValue(null);
    this.appraisalSalesForm.controls[0].get('districtId')?.patchValue(null);
    this.appraisalSalesForm.controls[0].get('appraisalZoneId')?.patchValue(null);
    this.appraisalZoneCodeControl.patchValue(null);
    let region = this.regions?.filter(r => r.id == this.appraisalSalesForm.controls[0].get('regionId')?.value)[0];
    this.appraisalSalesForm.controls[0].get('regionCode')?.patchValue(region.regionCode);
    this.appraisalSalesForm.controls[0].get('regionName')?.patchValue(region.regionName);
    console.log(region, this.appraisalSalesForm.controls[0]);
    if(region != null){
        this.forests = region.forests.sort(
            (a, b) => a.forestCode > b.forestCode ? 1 : -1);
        this.districts = [];
    }
}

setDistricts() {
    this.appraisalSalesForm.controls[0].get('districtId')?.patchValue(null); 
    this.appraisalZoneCodeControl.patchValue(null);
    let forest = this.forests?.filter(f => f.id == this.appraisalSalesForm.controls[0].get('forestId')?.value)[0];
    this.appraisalSalesForm.controls[0].get('forestCode')?.patchValue(forest.forestCode);
    this.appraisalSalesForm.controls[0].get('forestName')?.patchValue(forest.forestName);
    if(forest != null){
        this.districts = forest.districts.sort(
            (a, b) => a.districtCode > b.districtCode ? 1 : -1);
    }    
  }

  setAppraisalZone() {
    let district = this.districts?.filter(d => d.id == this.appraisalSalesForm.controls[0].get('districtId')?.value)[0];
    this.appraisalSalesForm.controls[0].get('districtCode')?.patchValue(district.districtCode);
    this.appraisalSalesForm.controls[0].get('districtName')?.patchValue(district.districtName);
    let appraisalZone = this.appraisalZones?.filter(a => a.districts.some(d => d.id == district?.id))[0];
    if(appraisalZone) {
      this.appraisalZoneCodeControl.patchValue(appraisalZone.appraisalZoneCode);
    }
}

  get appraiserRoleId(): number {
    return this.activeUserRole?.roleId;
  }

  getAppraisalSales() : AppraisalSale[] {
    return this.appraisalSales;
  }

  get unitOfMeasure(): string {
    return (this.appraisalSales.length > 0) ? this.appraisalSales[0].cuttingUnits[0].unitsOfMeasure.uomShortName : '';
  }

  get appraisalZoneCode(): string {
    return this.appraisalZoneCodeControl.value ? this.appraisalZoneCodeControl.value : 'N/A';
  }

  get saleName(): string {
    return (this.appraisalSalesForm.controls.length > 0 && this.appraisalSalesForm.controls[0].value.isEditable && this.appraisalSalesForm.controls[0].value.saleName) 
      ?  this.appraisalSalesForm.controls[0].value.saleName : this.saleNameControl.value ? this.saleNameControl.value : 'N/A';
  }

  get appraisalFormDebug(){
    return this.appraisalForm.value;
  }

  continue(event: any){
    event.preventDefault();
    console.log(this.appraisalSalesForm) //.controls[0].value)

    if(this.appraisalSalesForm.controls.length > 0 && this.appraisalSalesForm.controls[0].value.isEditable) {
      console.log(this.appraisalSalesForm.controls[0].value.isEditable);
      let newSale = this.appraisalSalesForm.controls[0].value;
      console.log('huh?');
      let appraisalSale: AppraisalSale = {
        id: null,
        saleNumber: newSale.saleNumber,
        saleName: newSale.saleName,
        saleDate: newSale.saleDate,
        appraisalStatus:  'Saved/Temp',
        districtId: newSale.districtId,
        district: null,
        cuttingUnits: [],
        inactivatedWhen_UTC: null,
        isActiveFlag: 1,
        insertedById: null,
        insertedBy: null,
        insertedWhen_UTC: null,
        updatedById: null,
        updatedBy: null,
        updatedWhen_UTC: null
      }
      this.appraisalSalesService.createAppraisalSale(appraisalSale).subscribe({
        next: response => {
          if(response.isSuccess) {
            this.toastr.success('Successfully new Appraisal Sale: ' + appraisalSale.saleName + '.', );
            this.appraisalSaleControl.patchValue(appraisalSale);
            this.appraisalSalesForm.controls[0].get('isEditable')?.patchValue(false);
            const rows = document.querySelectorAll(".appraisal-sale-row");
            this.renderer.addClass(rows[0], 'select-appraisal-sale-row');
            let userPortfolioButton: HTMLElement = document.getElementById("user-portfolio-btn") as HTMLElement;
            userPortfolioButton.click();
            userPortfolioButton.scrollIntoView({behavior:'smooth',block:'start'});

          } else {
            this.toastr.error('Failed to create new Appraisal Sale: ' + appraisalSale.saleName + '. Error: '+ response.error);
          }
        }
      });
    } else {
      let userPortfolioButton: HTMLElement = document.getElementById("user-portfolio-btn") as HTMLElement;
      userPortfolioButton.click();
      userPortfolioButton.scrollIntoView({behavior:'smooth',block:'start'});
    }
   
}

  selectRow(event: any, index: number) {
    const targetElement = event.currentTarget as HTMLElement;
    let appraisalSale = this.appraisalSalesForm.controls[index].value;
    let appraisalSaleEntity = this.appraisalSales.filter(a => a.id == appraisalSale.id)[0];
    const rows = document.querySelectorAll(".appraisal-sale-row");
    let editCheck = this.appraisalSalesForm.controls[0].value.isEditable;
    if(!appraisalSale.isEditable) {
      if(editCheck)
        this.removeRows();
      rows.forEach(row => {
        if(row.classList.contains('select-appraisal-sale-row'))
          row.classList.remove('select-appraisal-sale-row')
      });
      this.renderer.addClass(targetElement, 'select-appraisal-sale-row');
      if(index > 0) {
        this.appraisalZoneCodeControl.patchValue(appraisalSale.district.appraisalZone.appraisalZoneCode);
      } else {
        let appraisalZone = this.appraisalZones?.filter(a => a.districts.some(d => d.id == appraisalSale.districtId))[0];
        if(appraisalZone) {
          this.appraisalZoneCodeControl.patchValue(appraisalZone.appraisalZoneCode);
        }
      }
      this.saleNameControl.patchValue(appraisalSale.saleName)
    } else {
      rows.forEach(row => {
        if(row.classList.contains('select-appraisal-sale-row'))
          row.classList.remove('select-appraisal-sale-row')
      });
    }
    this.appraisalSaleControl.patchValue(appraisalSaleEntity);
    console.log(this.appraisalSaleControl);
    
  }


  submit(){
    return false;
  }

  showFocus(){
    this.main.nativeElement.focus();
  }

  focusModal(){
    this.modal.nativeElement.focus()
  }

}
