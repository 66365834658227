import { Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { FormArray, FormControl, FormGroup, FormGroupDirective } from '@angular/forms';
import { UsersService } from '../../../../services/users/users.service';
import { Subscription, map, take } from 'rxjs';
import { TooltipPosition } from '@angular/material/tooltip';
import { HandlecheckboxesService } from '../../../../services/handlecheckboxes.service';
import { AppraisalTypes } from '../../../../models/enums/appraisal-types'
import { IVisited } from '../../../../models/visitors/visited';
import { CalculationsService } from '../../../../services/calculations/calculations.service';
import { UserRoles } from '../../../../models/enums/user-roles';
import { UserRole } from '../../../../models/user/user-roles/user-role';
import { User } from '../../../../models/user/user';


@Component({
    selector: 'app-choose-appraisal-type',
    templateUrl: './choose-appraisal-type.component.html',
    styleUrls: ['./choose-appraisal-type.component.scss'],
    standalone: false
})

export class ChooseAppraisalTypeComponent implements OnInit, IVisited {
  user!: User;
  userRoles: UserRole[] = [];
  appraisalTypes = AppraisalTypes;
  positionOptions: TooltipPosition[] = ['after', 'before', 'above', 'below', 'left', 'right'];
  position = new FormControl(this.positionOptions[2]);
  @Input() formGroupName!: string
  @Output() childEvent = new EventEmitter();
  @ViewChild('standardrateappraisal', {static:true}) standardrateappraisal?:ElementRef;
  @ViewChild('standardrateappraisalinput', {static:true}) standardrateappraisalinput?:ElementRef;
  @ViewChild('comparisonappraisal', {static:false}) comparisonappraisal?:ElementRef;
  @ViewChild('comparisonappraisalinput', {static:false}) comparisonappraisalinput?:ElementRef;
  @ViewChild('standardRatetooltip', {static:true})StandardRatetooltip?:ElementRef;
    btnstate:any;
    form!: FormGroup;
    guest: any;
    isDisabled:boolean = false;

    appraisals = new FormControl({})
    
  
    clickEventSubscription!: Subscription;
    clickDisabledEventSubscription!: Subscription;
    volumeInfoForm!: FormGroup;
    appraisalGroupForms!: FormArray;
    appraisalTypeControl!: FormControl;
    appraiserRoleIdControl!: FormControl<number>;
    //appraiserRoleId!: number;
    appraisalTypeDesriptions: string[] = [
      "Standard Rate",
      "Streamlined TE",
      "Weighted Average TE",
      "Residual Value",
      "Multiple Regression TE",
      "Rate Determination",
    ];

    user_Roles = UserRoles;
    appraiser_RoleId!: number;
    activeUserRole!: UserRole;
    
    
    constructor(private rootFormGroup: FormGroupDirective, 
      public usersService: UsersService, 
      private checkboxHandler: HandlecheckboxesService,
      private calculationsService: CalculationsService ) {
        this.clickEventSubscription = this.checkboxHandler.getClickEvent().subscribe(()=>{
           this.changeButtonState()
        })

        this.clickDisabledEventSubscription = this.checkboxHandler.getDisabledClickEvent().subscribe(()=>{
          this.changeDisableState()
       })

    }

    public readProperty<T>(prop: string):T {
      let property = this[prop as keyof ChooseAppraisalTypeComponent];
      if (property == null) {
         throw Error(`Property "${prop}" does not exists`);
      }
      return property as T;
   } 

    ngOnInit() { 
      this.appraiser_RoleId = this.appraiserRoleId;
       this.usersService.currentUser$.pipe(take(1)).subscribe({
            next: user => {
              if (user){
                this.user = user;
                this.userRoles = this.user?.userRoles;
                this.activeUserRole = user.currentUserRole;
              }
            }
            
          });
      this.form = this.rootFormGroup.control.get(this.formGroupName) as FormGroup;
      this.appraisalTypeControl = this.form.controls["appraisalType"] as FormControl
      this.volumeInfoForm = this.rootFormGroup.control.get('volumeInformation') as FormGroup;
      this.appraisalGroupForms = this.volumeInfoForm.controls["appraisalGroups"] as FormArray;
      this.appraiserRoleIdControl = this.form.controls['appraiserRoleId'] as FormControl;

       this.guest = this.usersService.currentUser$.pipe(
        map(user => {
          if (!user) return null;
          if (user.userRoles.some(role => role.role.name === 'Guest')) {
            return true;
          } else {
            console.log('You cannot enter this area');
            return false;
          }
        })
      )

      this.calculationsService.visitChooseAppraisalTypeComponent(this);

    }

    get appraiserRoleId(): number {
      return this.activeUserRole?.roleId;
    }

    get appraisalSelection(): string {
      return this.appraisalTypeControl.value != null ? this.appraisalTypeDesriptions[this.appraisalTypeControl.value as number] : "N/A";
    }

    get appraisalType(): FormControl {
      return this.appraisalTypeControl;
    }

    setAppraisalType(appraisalType: AppraisalTypes){
      this.appraisalTypeControl.patchValue(appraisalType);
    }

    selectType(event: any){
      Array.prototype.forEach.call(document.getElementsByClassName('appraisal-type'), 
      item => item.classList.remove("appraisal-type-active"));
      event.currentTarget.classList.add("appraisal-type-active");  
  }

  continue(event: any){
        event.preventDefault();
        let element: HTMLElement = document.getElementById("panel-4-button") as HTMLElement;
        element.click();
        element.scrollIntoView({behavior:'smooth', block:'start'});
        
  }
  onKeydown(event:any){
    if(event.target){
      event.target.children[0].children[0].checked = true
    }
  }

  changeButtonState(){
       this.standardrateappraisal?.nativeElement.setAttribute('disabled','');
       this.standardrateappraisalinput?.nativeElement.setAttribute('disabled',true);
       this.StandardRatetooltip?.nativeElement.children[1].setAttribute('tabindex',-1)
       
  }
  
  changeDisableState(){
    this.standardrateappraisal?.nativeElement.removeAttribute('disabled','');
    this.standardrateappraisalinput?.nativeElement.removeAttribute('disabled','');
    this.StandardRatetooltip?.nativeElement.children[1].setAttribute('tabindex',0)
  }


  
  
 
}