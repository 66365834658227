<div class="usa-width-one-whole nt-info-area">
    <div [formGroup]="form" class="grid-container" id="">
      <div>
        <div class="grid-row">
          <div class="tablet:grid-col">
                <div class="usa-form-group">
                    <h2 class="usa-main-page-header">Western Wood Products Association (WWPA) Finished Goods Indices</h2>
                    <p class="subtitle">Added Monthly</p>
                </div>
                
                <div class="usa-form-group WWPA_form_group">
                  <div class="mattBtnContainer">
                    <span #statusTitle class="status">Status</span>
                    <mat-button-toggle-group aria-label="status" #statusBtns #statusBtn="matButtonToggleGroup" 
                            multiple="true"
                            [value]="selectedStatuses"
                            (change)="toggleStatus(statusBtn.value)" 
                            >
                            <mat-button-toggle *ngFor="let status of statuses" [value]="status" checked>{{status}}</mat-button-toggle>
                    </mat-button-toggle-group>
                  </div>
                  <div class="formContainer">
                    <div class="save-Status hide_status" #saveStatus>WWPA indices successfully added.</div>
                    <div [hidden]="showForm" #statusForm class="statusForm">
                      <div class="formBtns">
                        <button type="button" class="cancelLink" (click)="showPrevControls()" tabindex="0" aria-label="Cancel">Cancel</button>
                        <button type="button" class="usa-button-green" (click)="checkSave()" aria-label="Save Changes">Save Changes</button>
                      </div> 
                    </div>
                  
                    <button type="button" class="usa-button-green" (click)="toggleForm()" #toggleBtn aria-label="Insert new set of indices">Insert New Set of Indices</button><!--#toggleBtn-->
                  </div>
              </div>
              
                <table class="usa-table usa-table--stacked">
                  <caption>
                    WWPA Indices
                  </caption>
                  <thead>
                    <tr>
                      <th scope="col">Index YYYY/MM</th>
                      <th scope="col">WWPA Index Name</th>
                      <th scope="col">WWPA Index Code</th>
                      <th scope="col">TSA Species</th>
                      <th scope="col" style="min-width: 200px !important;">Index Value ($xxxx.xx/MBF)</th>
                      <th scope="col">Unit of Measure</th>
                      <th scope="col">Modified Date</th>
                      <th scope="col">Modified By</th>
                      <th scope="col">Created By</th>
                      <th scope="col">Created Date</th>
                    </tr>
                  </thead>
                  <tbody> 
                    <ng-container formArray="rowsForm" *ngFor="let index of rowsForm?.controls as FormArray; index as i">
                      <tr [ngClass]="index.get('isEditable')?.value === true ? 'edit-index-row': ''" >
                        <th [ngClass]="index.get('isNewRow')?.value ? 'edit-index' : index.get('child_IsActiveFlag')?.value == 1 ? 'active-index': ''" scope="row">{{index.get('indexYYYYMM')?.value}}</th>
                        <td>
                          {{index.get('wwpaIndexName')?.value}}
                        </td>
                        <td>
                          {{index.get('wwpaIndexCode')?.value}}
                        </td>
                        <td>
                          {{index.get('tsaSpeciesCode')?.value}}
                        </td>
                        <td [ngClass]="{'edit-index': index.get('isEditable')?.value}">
                          <div *ngIf="!index.get('isEditable')?.value">
                            <div class="grid-row" style="text-align:right">
                              <div class="tablet:grid-col">
                                {{index.get('indexValue')?.value}}
                              </div>
                              <div *ngIf="index.get('child_IsActiveFlag')?.value == 1" class="tablet:grid-col-2" style="margin-left:5px">
                                <button type="button" class="btn-icons" (click)="editWwpaIndex(index.get('child_Id')?.value, i)" aria-label="Edit">
                                  <svg class="edit-index-btn"  width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M3 17.25V21H6.75L17.81 9.94L14.06 6.19L3 17.25ZM20.71 7.04C21.1 6.65 21.1 6.02 20.71 5.63L18.37 3.29C17.98 2.9 17.35 2.9 16.96 3.29L15.13 5.12L18.88 8.87L20.71 7.04Z" fill="black"/>
                                  </svg> 
                                </button> 
                              </div>
                              
                            </div>
                                                 
                          </div>
                          <div *ngIf="index.get('isEditable')?.value">
                            
                            <div class="grid-row">
                              <div class="tablet:grid-col">
                                <input style="text-align:right;" type="number" class="form-control usa-input input-number unit-measure" [formControl]="getWwpaIndexControl(index.get('child_Id')?.value, i)" [step]="0.01" [min]="0.00" aria-label="Index Value"/> 
                                <div *ngIf="index.get('hasError')?.value === true" class="error">
                                  {{index.get('error')?.value}}
                                </div> 
                              </div>
                              <div class="tablet:grid-col-2" style="margin-left:10px;padding-top:20px">
                                <button *ngIf="index.get('child_Id')?.value !== null" type="button"class="btn-icons" (click)="saveWwpaIndex(index.get('child_Id')?.value, i)" aria-label="Save">
                                  <svg class="edit-index-btn" width="24" height="24" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.99997 11.17L1.82997 7L0.409973 8.41L5.99997 14L18 2L16.59 0.589996L5.99997 11.17Z" fill="black"/>
                                  </svg>
                                </button>
                                <button *ngIf="index.get('child_Id')?.value !== null" type="button" class="btn-icons" (click)="cancelSaveWwpaIndex(index.get('child_Id')?.value, i)" aria-label="Cancel">
                                  <svg class="edit-index-btn" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z" fill="black"/>
                                  </svg>
                                </button>
                              </div>
                            </div>
                           
                           
                          </div>    
                        </td>
                        
                        <td>
                          {{index.get('uomShortName')?.value}}
                        </td>
                        <td>
                          {{ index.get('child_UpdatedWhenUtc')?.value !== null ? index.get('child_UpdatedWhenUtc')?.value.toLocaleString() : ''}}
                        </td>
                        <td>
                          {{ index.get('child_UpdatedByName')?.value !== null ? index.get('child_UpdatedByName')?.value : ''}}
                        </td>
                        <td>
                          {{ index.get('child_InsertedByName')?.value !== null ? index.get('child_InsertedByName')?.value : ''}}
                        </td>
                        <td>
                          {{ index.get('child_InsertedWhenUtc')?.value !== null ? index.get('child_InsertedWhenUtc')?.value.toLocaleString() : ''}}

                        </td>
                      </tr>
                      </ng-container>
                    
                  </tbody>
                </table>
              
          </div>
        </div>
    </div>
    <app-modal id="modal-1" aria-modal="true" aria-labelledby="WWPA Finished Goods Indices" >

      <div class="content-container" tabindex="-1" #modal Focustrap><!--Focustrap-->
        <button type="button"  class="closeBtn" (click)="modalService.close('modal-1')"  aria-label="Close Modal" tabindex="0" cdkFocusRegionStart>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path d="M20.7456 3.33001C20.3551 2.93948 19.7219 2.93948 19.3314 3.33001L12.037 10.6244L4.74263 3.33001C4.35211 2.93948 3.71894 2.93948 3.32842 3.33001C2.93789 3.72053 2.93789 4.3537 3.32842 4.74422L10.6228 12.0386L3.32844 19.3329C2.93791 19.7235 2.93791 20.3566 3.32844 20.7472C3.71896 21.1377 4.35213 21.1377 4.74265 20.7472L12.037 13.4528L19.3314 20.7472C19.7219 21.1377 20.3551 21.1377 20.7456 20.7472C21.1361 20.3566 21.1361 19.7235 20.7456 19.333L13.4512 12.0386L20.7456 4.74422C21.1361 4.3537 21.1361 3.72053 20.7456 3.33001Z" fill="#757575"/>
          </svg>
        </button>
        <h2 class="heading"><strong>Save as Active or Inactive</strong></h2>
        <span class="subtext">Current active month: <strong>{{currentActiveMonth}}</strong></span><br/>
        <span class="subtext">New Index Analysis: </span>
        <ul>
          <li>{{ ulHeader }}</li>
            <ul>
              <li *ngFor="let listItem of ulItems">
                {{ listItem }}
              </li>
            </ul>
        </ul>
      
        <div class="modalForm">
          <div class="formContainer">
            <div>
              <div class="formControls">
                <label for="active" class="usa-label">
                   <input name="isActive" type="radio"  tabindex="0" value="active" [formControl]="statusControl" checked aria-checked="true"  (change)="setActive()" #isActive aria-label="Save as Active"/> Save as Active
                </label>
                
                <label for="inactive" class="usa-label">
                  <input name="isActive" type="radio"  tabindex="0" value="inactive" [formControl]="statusControl" (change)="setActive()" #inActive aria-label="Save as Inactive"/>Save as Inactive 
                 </label>
                
              </div>
              <div style="margin-top:20px">
                <p><strong>Are you sure you want to save these changes?</strong></p>
                <p class="subtext_p">You will not be able to go back after you save changes.</p>
              </div>
              <div class="formBtns">
                <button type="button" class="saveChanges_btn" (click)="onSave();" tabindex="0" aria-label="Save changes">Save Changes</button>
                <button type="button" class="cancelLink" (click)="modalService.close('modal-1')" tabindex="0" aria-label="Cancel">Cancel</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      </app-modal>
  </div>
