
<div *ngIf="appraisalGroupForms.length > 0" class="grid-container accordion-grid-container" style="padding-right:0 !important;">
    <div class="usa-form-group">
        <div class="usa-appraisal-groups-wrapper" style="width:100%">
            <h3 class="title">{{title}}</h3>
            <div class="grid_appraisal_container">
                <div class="grid-item" *ngFor="let appraisalGroup of appraisalGroupForms.controls | paginate: {itemsPerPage: 6, currentPage:p}; let i = index">
                    <p>{{appraisalGroup.controls["treeSpeciesName"].value}}</p>
                    <div class="rates_vol_wrapper">
                        <div class="volume">Volume: {{appraisalGroup.controls["totalVolume"].value}}/{{appraisalGroup.controls["uomShortName"].value}}</div>  <div class="standardrates">Standard Rate: {{appraisalGroup.controls["standardRate"].value.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) }}</div>
                    </div>
                    <div class="volume">Volume x Standard Rate: {{(appraisalGroup.controls["totalVolume"].value * appraisalGroup.controls["standardRate"].value).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) }}</div>
                    
                </div>
            </div>
            <!-- <mat-grid-list cols="2" rowHeight="85px" gutterSize="16px">
                <mat-grid-tile  *ngFor="let appraisalGroup of appraisalGroupForms.controls | paginate: {itemsPerPage: 6, currentPage:p}">
                    <p>{{appraisalGroup.controls["treeSpeciesName"].value}}</p>
                    <div class="rates_vol_wrapper">
                        <div class="volume">Volume: {{appraisalGroup.controls["totalVolume"].value}}/{{appraisalGroup.controls["uomShortName"].value}}</div>  <div class="standardrates">Standard Rate: {{appraisalGroup.controls["standardRate"].value.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) }}</div>
                    </div>
                    <div class="volume">Volume x Standard Rate: {{(appraisalGroup.controls["totalVolume"].value * appraisalGroup.controls["standardRate"].value).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) }}</div>
                </mat-grid-tile>
            </mat-grid-list> -->
            <div *ngIf="appraiserRoleId > 1" class="has-text-centered">
                <pagination-controls (pageChange)="p = $event"></pagination-controls>
            </div>
        </div>
    </div>
</div>

            