<div>
    <div class="Input-Group-Wrapper">
        <input type={{type}} [class.is-invalid]="control.touched && control.invalid" name="{{name}}" attr.aria-label="{{name}}" class="form-control usa-input input-number unit-measure" 
            [formControl]="control" placeholder={{label}} step={{step}} min={{min}} max={{max}} (change)="checkMax()">
        <div [class.is-disabled]="control.disabled || disabled" class="input-groupappend" *ngIf="unitOfMeasure"><span *ngIf="uomPerUnit">/</span>{{unitOfMeasure}}</div> 
    </div>
    
    <div class="error-contents">
       <div class="invalid-feedback" *ngIf="control.invalid && (control.dirty || control.touched)">
          <span  *ngIf="control.errors?.['required']">Error:Please enter a {{label}}</span>
       </div>
    </div>
    
    
    <div class="invalid-feedback" *ngIf="control.errors?.['minlength']">
        Error:{{label}} must be at least {{control.errors?.['minlength'].requiredLength}} characters
    </div>
    <div class="invalid-feedback" *ngIf="control.errors?.['maxlength']">
        Error:{{label}} must be at most {{control.errors?.['maxlength'].requiredLength}} characters
    </div>
    <div class="invalid-feedback" *ngIf="control.errors?.['notMatching']">
        Error:Passwords do not match
    </div>
</div>