import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AppraisalTypes } from '../../models/enums/appraisal-types';
import { UserRoles } from '../../models/enums/user-roles';

@Injectable({
  providedIn: 'root'
})
export class WwpaCalculationsService{
//#region Observables

    // private requiredCollectionsSource = new BehaviorSubject<number>(0.00);
    // requiredCollections$ = this.requiredCollectionsSource.asObservable();


//#endregion

//#region Private Properties

    // National Forest Foundation Fund
    private readonly nff = 0.25;
    private totalSaleVolume: number = 0.00;
    private timberPropertyValue: number = 0.00;
    private regenerationCost: number = 0.00;
    private basePeriodRate: number = 0.00;
    private baseSkidYard: number = 0.00;
    private baseHaul: number = 0.00;
    private baseRoadMaintenance: number = 0.00;
    private baseTempDevelopments: number = 0.00;
    private baseSlashDisposal: number = 0.00;
    private basePeriodRateInitial: number = 0.00;
    private baseSkidYardInitial: number = 0.00;
    private baseHaulInitial: number = 0.00;
    private baseRoadMaintenanceInitial: number = 0.00;
    private baseTempDevelopmentsInitial: number = 0.00;
    private baseSlashDisposalInitial: number = 0.00;
    private appraisalType!: AppraisalTypes;
    private userRole: UserRoles = 1;
    private contractBaseValueCoefficient: number = 0.00;
    private appraisalGroups!: any[];
//#endregion
   
//#region Construction
    constructor(private http: HttpClient) { }
//#endregion

}